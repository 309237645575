import styled from "styled-components";
import ButtonUnstyled from "@mui/base/ButtonUnstyled";

export const Button = styled(ButtonUnstyled)`
  text-decoration: underline;
  margin: 0;
  color: ${(props) => props.theme.colors.blue};
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
`;
