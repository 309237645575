import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Textfield from "components/inputs/Textfield";
import Button from "components/button/DefaultButton";
import * as T from "components/layout/titles";
import Box from "@mui/material/Box";
import * as S from "./components/styles";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "http-services/auth";
import useConfig from "services/config/useConfig";

enum PageMode {
  INITIAL = 1,
  DONE = 2,
}

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { language, translation } = useConfig();

  const [mode, setMode] = useState(PageMode.INITIAL);
  const [sentEmail, setSentEmail] = useState("");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      const { email, success } = await resetPassword(data.email);

      if (success) {
        setMode(PageMode.DONE);
        setSentEmail(email);
      } else {
        setError("email", { type: "custom", message: "E-mail inválido" });
      }
    } catch (error: any) {
      setError("email", {
        type: "custom",
        message:
          "Não foi possível recuperar a senha. Favor tente novamente mais tarde",
      });
    }
  };

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <S.Wrapper>
      {mode === PageMode.INITIAL && (
        <>
          <T.Title textAlign="center">
            {translation.auth.resetPassword.title.main[language]}
          </T.Title>
          <S.RecoverSubtitle>
            <span>
              {translation.auth.resetPassword.title.subtitle[language]}
            </span>
          </S.RecoverSubtitle>

          <Controller
            name="email"
            control={control}
            rules={{
              required: translation.errorMessage.form.required[language],
              pattern: {
                value:
                  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi,
                message: translation.errorMessage.form.invalidEmail[language],
              },
            }}
            render={({ field: { onChange, value } }) => (
              <Textfield
                placeholder={
                  translation.auth.resetPassword.form.placeholder.email[
                    language
                  ]
                }
                value={value}
                onChange={onChange}
                error={Boolean(errors.email?.message)}
                messageError={errors.email?.message}
                onKeyDown={handleEnter}
              />
            )}
          />

          <S.ButtonWrapper>
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              {translation.buttons.auth.send[language]}
            </Button>
            <Button variant="text" onClick={() => navigate("/")}>
              {translation.buttons.auth.back[language]}
            </Button>
          </S.ButtonWrapper>
        </>
      )}
      {mode === PageMode.DONE && (
        <>
          <T.Title textAlign="center">
            {translation.auth.resetPassword.title.emailSent[language]}
          </T.Title>
          <S.RecoverSubtitle noMargin>
            <span>
              {translation.auth.resetPassword.title.emailSentSubtitle[language]}{" "}
              :)
            </span>
          </S.RecoverSubtitle>
          <S.RecoverSubtitle>
            <span>
              {translation.auth.resetPassword.message.success[language]}{" "}
              {sentEmail}
            </span>
          </S.RecoverSubtitle>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <S.EmailIcon sx={{ fontSize: "150px" }} />
          </Box>
          <S.ButtonWrapper>
            <Button variant="contained" onClick={() => navigate("/")}>
              OK
            </Button>
          </S.ButtonWrapper>
        </>
      )}
    </S.Wrapper>
  );
};

export default ResetPasswordPage;
