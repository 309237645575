import styled from "styled-components";

type Props = {
  textAlign?: string;
};

export const Title = styled.div<Props>`
  font-size: 24px;
  font-weight: 700;
  text-align: ${(props) => props.textAlign};
`;

export const ItemTitle = styled(Title)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.blue};
  font-weight: 500;
`;

export const SectionTitle = styled(Title)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 1rem;
`;
