export type CriteriaData = {
  label: string;
  note: string;
  status?: string;
  key?: string;
};

export type CriteriaAnalysisResponse = {
  status: Status;
  compliancePotential: number;
  criteria: CriteriaData[];
  analysisRequested: boolean;
  id?: number;
  step: number;
  requestedBy?: string;
  requestedAt?: string;
  updatedBy?: string;
  updatedAt?: string;
  createdAt?: string;
};

export type Plg = {
  p_upn: string;
  p_isActive: boolean;
  p_phase: string;
  p_city: string;
  p_state: string;
  responsible_name: string;
  titular_name: string;
};

export type FilteredPlg = {
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
  id: string;
  substance: string;
  upn: string;
  isActive: boolean;
  phase: string;
  titularId: number;
  responsibleId: number;
  city: string;
  state: string;
  number: string;
  year: string;
};

export enum Status {
  Ok = "Ok",
  NotOk = "NotOk",
  Pending = "Pending",
  InProgress = "InProgress",
  Blocked = "Blocked",
  Partial = "Partial"
}
