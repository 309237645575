import React, { MutableRefObject } from "react";
import {
  Box,
  Stack,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Icon,
} from "@mui/material";
import connectIcon from "assets/image/icons/faq/connect.svg";
import satelliteIcon from "assets/image/icons/faq/satellite.svg";
import markIcon from "assets/image/icons/faq/mark.svg";
import * as T from "components/layout/titles";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import useConfig from "services/config/useConfig";

import styled from "styled-components";
import { Accordion as AccordionMui } from "@mui/material";
import { headerStyle } from "../map/components/MapOverlay/SearchBar/ModalDataAccess/CriteriaAnalysis/ComplianceResult/PdfLayout/Default/styles";
import { bodyText, headerText } from "./styles";

export const Accordion = styled(AccordionMui)`
  &:before {
    background: transparent !important;
  }
`;

type Props = {
  faqRef: MutableRefObject<any>;
};

const FAQ = (props: Props) => {
  const { translation, language } = useConfig();

  const t = translation.home.FAQ;

  const [expanded, setAccordionsExpanded] = React.useState([
    false,
    false,
    // false,
  ]);

  const handleExpand =
    (position: number) => (_event: unknown, isExpanded: boolean) => {
      const accordionsState = [...expanded];
      accordionsState[position] = isExpanded;
      setAccordionsExpanded([...accordionsState]);
    };

  return <Box sx={{ maxWidth: '800px' }}>
    <Typography sx={headerText}>{t.title[language]}</Typography>
    <Typography sx={bodyText}>{t.description[language]}</Typography>
    <Box>
      {expanded.map((isExpanded, index: number) => {
        return (<Accordion elevation={0} key={index} onChange={handleExpand(index)} sx={{ background: "transparent", p: 0, m: 0, border: "none" }}>
          <AccordionSummary sx={{ p: 0, m: 0, border: "none" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Box sx={{ color: "#dd9d0d", background: "white", borderRadius: 999, display: "flex", justifyItems: "center", alignContent: "center", p: .5, border: "2px solid #dd9d0d" }}><Icon sx={{ fontSize: 20 }}>{expanded[index] ? "remove" : "add"}</Icon></Box>
              <Typography sx={{ fontWeight: "bold", color: "#dd9d0d", textTransform: "uppercase" }}>{t.pkg[index as 0 | 1 | 2].title[language]}</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0, m: 0 }}>
            <Typography sx={{ whiteSpace: "pre-line" }} dangerouslySetInnerHTML={
              { __html: t.pkg[index as 0 | 1 | 2].description[language] }
            } />

          </AccordionDetails>
        </Accordion>);
      })}
    </Box>
  </Box >
};

export default FAQ;
