import React from "react";
import SelectMUI, { SelectProps } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import * as S from "../Textfield/styles";

type Item = {
  value: any;
  label: string;
  disabled?: boolean;
};

type Props = {
  label?: string;
  error?: boolean;
  placeholder?: string;
  messageError?: string;
  type?: string;
  items: Item[];
  render?: React.FC<SelectProps & Props>;
};

const Select = (props: Props & SelectProps) => {
  const InputComponent = props.render ? props.render : SelectMUI;

  const Items = props.items.map((i) => (
    <MenuItem value={i.value} disabled={i.disabled}>
      {i.label}
    </MenuItem>
  ));

  return (
    <FormControl variant="outlined" sx={props.sx}>
      <S.InputText>{props.label}</S.InputText>
      <InputComponent
        {...props}
        sx={{ height: "3rem", ...(props.sx ?? {}) }}
        displayEmpty
      >
        {Items}
      </InputComponent>
      <S.InputText error>{props.error && props.messageError}</S.InputText>
    </FormControl>
  );
};

export default Select;
