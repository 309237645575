import {
  register as registerHttpService,
  RegisterDto,
  login as loginHttpService,
  LoginDto,
} from "http-services/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authenticate, setUserdata, logout as logoutAction } from "store/auth";

type ErrorCallback = (message: string, status: number) => void;

enum StorageAuthKeys {
  Token = "nap_access_token",
  UserName = "nap_user_name",
  UserProfile = "nap_user_profile",
}

export { StorageAuthKeys };

export const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (data: LoginDto, errorCallback: ErrorCallback) => {
    try {
      const { accessToken, userName, userProfile } = await loginHttpService(
        data
      );

      localStorage.setItem(StorageAuthKeys.Token, accessToken);
      localStorage.setItem(StorageAuthKeys.UserName, userName);
      localStorage.setItem(StorageAuthKeys.UserProfile, userProfile);

      dispatch(setUserdata({ name: userName, profile: userProfile }));
      dispatch(authenticate());

      navigate("/map");
    } catch (error: any) {
      const { message, statusCode } = error?.response?.data || {};

      errorCallback(message, statusCode || 400);
    }
  };

  const register = async (data: RegisterDto, errorCallback: ErrorCallback) => {
    try {
      const { user } = await registerHttpService(
        data
      );

      return user;
    } catch (error: any) {
      const { message, status } = error.response.data;
      errorCallback(message, status);
    }
  };

  const logout = () => {
    localStorage.clear();
    dispatch(logoutAction());

    navigate("/");
  };

  return { login, register, logout };
};
