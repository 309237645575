import React, { memo, MutableRefObject } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack, Typography, Button, Box } from "@mui/material";
import { PersonOutline } from "@mui/icons-material";
import { useAuthData } from "../../services/auth";
import BaseHeader from "./components/BaseHeader";
import UserProfile from "./components/UserProfile";
import LanguageSelect from "./components/LanguageSelect";
import * as S from "./styles";
import useConfig from "services/config/useConfig";
import { is } from "date-fns/locale";

type Props = {
  aboutRef?: MutableRefObject<any>;
  faqRef?: MutableRefObject<any>;
  footerRef?: MutableRefObject<any>;
};

const buttonStyle = {
  color: "white",
  fontWeight: "normal",
};

const Header = memo((props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuthData();
  const { translation, language } = useConfig();

  const scrollTo =
    (refElement?: MutableRefObject<any>) =>
      (_event: React.MouseEvent<HTMLElement>) => {
        if (refElement) {
          refElement.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      };

  const isHome = location.pathname === "/";

  return (
    <BaseHeader>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "center",
          gap: "2rem",
          ml: isHome ? "100px" : "auto",
          width: isHome ? "100%" : "100%",
        }}
      >
        <Box sx={{ gap: 4, display: "flex", visibility: isHome ? "visible" : "hidden" }}>
          <Button sx={buttonStyle} onClick={scrollTo(props.aboutRef)}>
            {translation.header.items.about[language]}
          </Button>
          <Button sx={buttonStyle} onClick={scrollTo(props.faqRef)}>
            {translation.header.items.how[language]}
          </Button>
          <Button sx={buttonStyle} onClick={scrollTo(props.footerRef)}>
            {translation.header.items.employees[language]}
          </Button>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {user ? (<>
            <UserProfile user={user}></UserProfile>
          </>
          ) : (
            <>
              <Button variant="outlined" size="medium" sx={{ color: "#fccb5b", borderColor: "#fccb5b", borderRadius: "99px" }} onClick={() => navigate("/login")}>
                <Typography>Login</Typography>
              </Button>
                <Button variant="outlined" size="medium" sx={{ color: "#fccb5b", borderColor: "#fccb5b", borderRadius: "99px" }} onClick={() => navigate("/register")}>
                  <Typography>Cadastro</Typography>
                </Button>
            </>
          )}
          <LanguageSelect />
        </Box>
      </Box>
    </BaseHeader>
  );
});

export default Header;
