import React, {
  PropsWithoutRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as RL from "react-leaflet";

import L from "leaflet";
import "leaflet.vectorgrid";
import vectorTileLayer from "leaflet-vector-tile-layer";
import { ALL_STYLES, LayerStyle } from "../services/layers";
import { isNumber } from "lodash";
import { bbox2LatLngBounds } from "../utils/upn";

export type Props = PropsWithoutRef<{
  url: string;
  filters?: string[];
  zIndex?: number;
  minZoom?: number;
  maxZoom?: number;
  opacity?: number;
  featureId?: string;
  show?: boolean;
  type?: string;
  onFeatureChange?: (upn: string) => void;
}>;

const byEnabled = (names: string[]) => (style: LayerStyle) =>
  names.includes(style.name);

const byMatchingFeature = (properties: any) => (style: LayerStyle) =>
  style.matches(properties);

const byPriority = (styleA: LayerStyle, styleB: LayerStyle) => {
  if (!isNumber(styleA.priority) || !isNumber(styleB.priority)) return 0;
  if (styleA.priority > styleB.priority) return -1;
  if (styleA.priority <= styleB.priority) return 1;

  return 0;
};

export const Tiles: React.FC<Props> = ({
  url,
  filters: f,
  featureId: fid,
  onFeatureChange,
  show = true,
  zIndex = 2,
  minZoom = 1,
  maxZoom = 14,
  opacity = 0.5,
}) => {
  const map = RL.useMap();
  const featureId = useRef<string>("");
  const filters = useRef<string[]>([]);

  const [layer, setLayer] = useState<any>();

  const getStyleForFeature =
    (overrides: Partial<L.PathOptions> = {}) =>
    (feature: any | null = null) => {
      if (!show) {
        return { fill: false, color: "transparent" };
      }

      const p = feature?.properties ?? {};
      const f = filters.current;

      if (featureId.current && featureId.current === p?.upn) {
        return {
          weight: 3,
          zIndex: 1000,
          fill: true,
          opacity: 1,
          color: "#FABC0F",
          fillColor: "transparent",
        };
      }

      const style = ALL_STYLES.filter(byEnabled(f))
        .filter(byMatchingFeature(p))
        .sort(byPriority)[0];

      if (!style) return { fill: false, color: "transparent" };
      if (f && !f.includes(style.name))
        return { fill: false, color: "transparent" };

      return {
        color: style.color,
        fill: true,
        weight: 1,
        fillColor: "transparent",
        fillOpacity: 1,
        opacity: 1,
        bubblingMouseEvents: false,
        ...overrides,
      } as Partial<L.PathOptions>;
    };

  useEffect(() => {
    filters.current = f ?? [];
    if (fid) featureId.current = fid.toString();

    layer?.setStyle(getStyleForFeature({ fillOpacity: opacity }));
  }, [f, fid, layer, opacity, show]);

  useEffect(() => {
    const layer = vectorTileLayer(url, {
      subdomains: "abcd",
      type: "layers",
      interactive: true,
      keepBuffer: 2,
      maxDetailZoom: maxZoom,
      minDetailZoom: minZoom,
      updateWhenZooming: false,
      zIndex,
      style: getStyleForFeature({ fillOpacity: opacity }),
    } as Partial<L.GridLayerOptions>);

    layer.setOpacity(1);

    map.addLayer(layer);

    setLayer(layer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (layer) {
      layer.on("click", (e: any) => {
        const l = e?.layer;
        const properties = l?.properties ?? {};

        if (l.getPopup()) return;

        if (properties?.type !== "plg") {
          let type;
          
          switch (properties?.type) {
            case "Ibama Embargo":
              type = "Embargo Ambiental (Ibama)";
              break;
            default:
              type = properties?.type;
          }

          new L.Popup({
            className: "text",
          })
            .setContent(
              `
            <div style="font-size: 1rem; font-weight: 700; margin-bottom: 0.5rem">${type}</div>
            <div style="text-transform: uppercase; margin-bottom: 1rem;">${
              properties?.NOME_UC1 ?? properties?.terrai_nom ?? `Nº Auto de Infração: ${properties?.num_auto_i}`
            }</div>
            <div style="font-size: 0.75rem; color: gray;">${
              properties?.modalidade ?? properties?.nom_pessoa ?? ""
            }</div>
          `
            )
            .setLatLng(e?.latlng)
            .addTo(map);
        } else {
          const { upn, bbox } = e?.layer?.properties ?? {};

          if (upn === featureId.current) return;

          featureId.current = upn?.toString();

          layer?.setStyle(getStyleForFeature({ fillOpacity: opacity }));

          const bounds = bbox2LatLngBounds(bbox);

          if (bounds) {
            map.flyToBounds(bounds, { maxZoom: 12, animate: false });
          }

          if (onFeatureChange) onFeatureChange(upn);
        }
      });

      return () => {
        layer.off("click");
      };
    }
  }, [featureId, fid, layer, map, onFeatureChange, opacity]);

  return <></>;
};
