import React, { PropsWithoutRef, useEffect, useState } from "react";
import Table from "components/data-display/Table";
import { getPeopleData, PeopleData } from "http-services/data-access";
import { Wrapper, StyledTitle } from "../styles";
import useConfig from "services/config/useConfig";

type Props = PropsWithoutRef<{
  upn?: string;
}>;

const RelatedMember = ({ upn }: Props) => {
  const { translation, language } = useConfig();

  const [data, setData] = useState<PeopleData[]>([]);

  useEffect(() => {
    if (!upn) return;

    getPeopleData(upn).then(setData);
  }, [upn]);

  return (
    <Wrapper>
      <StyledTitle>
        {translation.modal.relationship.title[language]}
      </StyledTitle>
      <Table
        headerKeys={[
          {
            key: "relation",
            label: translation.modal.relationship.relationship[language],
          },
          { key: "name", label: translation.modal.relationship.name[language] },
          {
            key: "document",
            label: translation.modal.relationship.cpfCnpj[language],
          },
          {
            key: "startDate",
            label: translation.modal.relationship.startDate[language],
          },
          {
            key: "endDate",
            label: translation.modal.relationship.endDate[language],
          },
        ]}
        data={data}
        gridColumns="10% 1fr 10% 10% 10%"
      />
    </Wrapper>
  );
};

export default RelatedMember;
