import { Languages } from "types/config/i18n";
export { auth } from "./auth";
export { header } from "./header";
export { home } from "./home";
export * as map from "./map";
export * as modal from "./modal";
export { packageCriteria } from "./package";
export { pdf } from "./pdf";

export const buttons = {
  auth: {
    enter: {
      [Languages.PORTUGUESE]: "Entrar",
      [Languages.ENGLISH]: "Enter",
    },
    back: {
      [Languages.PORTUGUESE]: "Voltar",
      [Languages.ENGLISH]: "Back",
    },
    send: {
      [Languages.PORTUGUESE]: "Enviar",
      [Languages.ENGLISH]: "Send",
    },
  },
  map: {
    seeAnalysis: {
      [Languages.PORTUGUESE]: "Ver Análise",
      [Languages.ENGLISH]: "See Analysis",
    },
    reset: {
      [Languages.PORTUGUESE]: "Limpar",
      [Languages.ENGLISH]: "Clear",
    },
  },
  home: {
    startFree: {
      [Languages.PORTUGUESE]: "Navegar gratuitamente",
      [Languages.ENGLISH]: "Start for Free",
    },
  },
  analysisCriteria: {
    validate: {
      [Languages.PORTUGUESE]: "Validar Critérios",
      [Languages.ENGLISH]: "Validate Criteria",
    },
    cancel: {
      [Languages.PORTUGUESE]: "Cancelar",
      [Languages.ENGLISH]: "Cancel",
    },
    finish: {
      [Languages.PORTUGUESE]: "Finalizar",
      [Languages.ENGLISH]: "Conclude",
    },
  },
};

export const errorMessage = {
  form: {
    required: {
      [Languages.PORTUGUESE]: "Campo obrigatório",
      [Languages.ENGLISH]: "Required field",
    },
    emailAlreadyRegistered: {
      [Languages.PORTUGUESE]: "E-mail ja cadastrado",
      [Languages.ENGLISH]: "E-mail already registered",
    },
    invalidEmail: {
      [Languages.PORTUGUESE]: "Deve ser um e-mail válido",
      [Languages.ENGLISH]: "It must be a valid e-mail address",
    },
    invalidPassword: {
      [Languages.PORTUGUESE]: "Deve ser uma senha válida",
      [Languages.ENGLISH]: "It must be a valid password",
    },
    auth: {
      loginFail: {
        [Languages.PORTUGUESE]:
          "Não foi possível realizar o login. Tente novamente mais tarde",
        [Languages.ENGLISH]: "Could not login. Try again later",
      },
    },
  },
};

export const system = {
  downloadReport: {
    [Languages.PORTUGUESE]: "Baixar Relatório",
    [Languages.ENGLISH]: "Download Report",
  },
  downloadProgress: {
    [Languages.PORTUGUESE]: "Transferindo...",
    [Languages.ENGLISH]: "Downloading...",
  },
  reportTitle: {
    [Languages.PORTUGUESE]: "Relatório de conformidade:",
    [Languages.ENGLISH]: "Report:",
  },
  reportWarning: {
    [Languages.PORTUGUESE]: "Limitado a 50.000 linhas",
    [Languages.ENGLISH]: "Limited to 50,000 lines",
  },
  case: {
    [Languages.PORTUGUESE]: "Processo",
    [Languages.ENGLISH]: "Case",
  },
  package: {
    [Languages.PORTUGUESE]: "Pacote",
    [Languages.ENGLISH]: "Package",
  },
  nextStepPackageMessage: {
    [Languages.PORTUGUESE]:
      "Parabéns! Este pacote foi concluído, prossiga para o próximo nível de análise",
    [Languages.ENGLISH]:
      "Congratulations! This package has been completed, proceed to the next analysis level",
  },
  status: {
    compliant: {
      [Languages.PORTUGUESE]: "Conforme",
      [Languages.ENGLISH]: "Compliant",
    },
    nonCompliant: {
      [Languages.PORTUGUESE]: "Não conforme",
      [Languages.ENGLISH]: "Non-compliant",
    },
    partial: {
      [Languages.PORTUGUESE]: "Reavaliação",
      [Languages.ENGLISH]: "Reevaluation",
    },
    pending: {
      [Languages.PORTUGUESE]: "Pendente",
      [Languages.ENGLISH]: "Pending",
    },
    received: {
      [Languages.PORTUGUESE]: "Recebido",
      [Languages.ENGLISH]: "Received",
    },
    notReceived: {
      [Languages.PORTUGUESE]: "Não recebido",
      [Languages.ENGLISH]: "Not received",
    },
  },
  pdf: {
    generate: {
      [Languages.PORTUGUESE]: "Gerar PDF",
      [Languages.ENGLISH]: "Generate PDF",
    },
    download: {
      [Languages.PORTUGUESE]: "Baixar PDF",
      [Languages.ENGLISH]: "Download PDF",
    },
    generating: {
      [Languages.PORTUGUESE]: "Gerando PDF...",
      [Languages.ENGLISH]: "Generating PDF...",
    },
  },
};


export const user =
{
  profile:
  {
    buyer:
    {
      [Languages.PORTUGUESE]:
        "Comprador",
      [Languages.ENGLISH]:
        "Buyer",
    },
    producer:
    {
      [Languages.PORTUGUESE]:
        "Produtor",
      [Languages.ENGLISH]:
        "Producer",
    },
    technician:
    {
      [Languages.PORTUGUESE]:
        "Técnico",
      [Languages.ENGLISH]:
        "Technician",
    },
  },
};
