import React, { } from "react";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import useConfig from "services/config/useConfig";
import { bodyText, headerText } from "./styles";
import Info from '../../assets/image/pages/landing-page/Infografico_site-PTO@2x.png'

import manual from '../../assets/files/manual.pdf';

const AboutSection = () => {
  const { translation, language: l } = useConfig();

  const t = translation.home.about

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={5}>
        <Typography sx={headerText}>{t.title[l]}</Typography>
        <Typography sx={bodyText}>{t.description[l]}</Typography>
        <a href={manual} target="_blank">
          <Button
            sx={{ marginTop: "1rem", background: "#f9b632", color: "black" }}
            variant="contained"
            color="warning"
          >
            Baixar o Manual do Usuário
          </Button>
        </a>
      </Grid>
      <Grid item xs={12} md={7}>
        <img src={Info} alt="" />
      </Grid>
    </Grid>
  );
};

export default AboutSection;
