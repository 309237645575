import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Avatar, Menu, MenuItem, Typography, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useAuth } from "../../../services/auth";
import { Profile, ProfileLabel } from "../../../types/auth";
import useConfig from "../../../services/config/useConfig";
import { Languages } from "../../../types/config/i18n";

type UserProfileProps = React.PropsWithChildren<{
  user:
  | {
    name: string;
    profile: Profile;
  }
  | undefined
  | null;
  onClick?: (e: React.MouseEvent) => void;
  onTouchStart?: (e: React.TouchEvent) => void;
}>;

const UserProfile = ({ user, children, onClick, onTouchStart }: UserProfileProps) => {
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const { language, translation } = useConfig();

  const translateProfile = (name: string): string => {
    const translationTitlesData = Object.values(translation.user.profile);

    const translatData = translationTitlesData.find(
      (t) => t[Languages.PORTUGUESE].toLowerCase() === name.toLowerCase(),
    );
    return translatData?.[language] || name;
  };

  if (!user) return <></>;

  return (
    <PopupState variant="popover" popupId="profile">
      {(popupState) => (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: ".5rem",
            }}
            onClick={onClick}
            onTouchStart={onTouchStart}
          >
            <Avatar alt={user?.name}>{user?.name?.charAt(0)}</Avatar>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                }}
              >
                {user.name}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "0.75rem",
                }}
              >
                {translateProfile(ProfileLabel[user.profile])}
              </Typography>
            </Box>
            <IconButton color="inherit" style={{ color: "white" }} {...bindTrigger(popupState)}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{
              sx: {
                minWidth: "200px",
                p: 0,
              },
            }}
          >
            <MenuItem disabled={location.pathname == "/map"} onClick={() => navigate("/map")}>
              Mapa
            </MenuItem>
            {user.profile == 'admin' && <MenuItem disabled={location.pathname === "/admin"} onClick={() => navigate("/admin")}>
              Adicionar Usuário
            </MenuItem> }
            <MenuItem onClick={logout}>Sair</MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default UserProfile;
