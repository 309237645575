import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { Profile } from "types/auth";

export type State = {
  isAuthenticated: boolean;
  isAuthDataLoaded: boolean;
  user: {
    name: string;
    profile: Profile;
  } | null;
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    isAuthDataLoaded: false,
    user: null,
  } as State,
  reducers: {
    authenticate: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    setUserdata: (
      state,
      action: PayloadAction<{ name: string; profile: Profile }>
    ) => {
      const { name, profile } = action.payload;

      state.user = { name, profile };
    },

    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isAuthDataLoaded = action.payload;
    },
  },
});

export const { authenticate, logout, setUserdata, setIsLoaded } =
  authSlice.actions;

export const Getters = {
  userData: (store: RootState) => store.auth.user,
  isAuthenticate: (store: RootState) => store.auth.isAuthenticated,
  isLoaded: (store: RootState) => store.auth.isAuthDataLoaded,
};

export default authSlice.reducer;
