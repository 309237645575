import React, { memo, useState, useEffect, FC, useRef } from "react";
import { Box, CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { CriteriaAnalysisResponse, Plg } from "types/data-access";
import { getCriteriaAnalysis } from "http-services/data-access";
import ComplianceResult from "./ComplianceResult";
import CriteriaResult from "./CriteriaResult";
import { Wrapper } from "../styles";
import { Status } from "types/data-access";
import FooterInfo from "./FooterInfo";
import StepsDisplayer from "./StepsDisplayer";

type Props = {
  upn: string;
  screenshooter?: SimpleMapScreenshoter;
  plg: Plg | undefined;
};

const CriteriaAnalysis: FC<Props> = (props: Props) => {
  const [page, setPage] = useState(0);
  const [updateData, setUpdateData] = useState(0);
  const [pageErrors, setPageErrors] = useState<number[]>([]);
  const [showErrors, setShowErrors] = useState(true);
  const [criteriaAnalysisData, setCriteriaAnalysisData] = useState<
    CriteriaAnalysisResponse[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const screenRef = useRef(null);

  const messageError = `Houve um erro ao solicitar os pacotes: ${pageErrors.join(
    ", "
  )}`;

  const currentCriteriaAnalysisData = criteriaAnalysisData[page] || {
    status: Status.NotOk,
    compliancePotential: 0,
    criteria: [],
    analysisRequested: false,
  };

  const setAnalysisRequested = (page: number, value: boolean) => {
    const analysisData = criteriaAnalysisData[page];
    const criteriaAnalysisList = [...criteriaAnalysisData];
    criteriaAnalysisList.splice(page, 1, {
      ...analysisData,
      analysisRequested: value,
    });

    setCriteriaAnalysisData([...criteriaAnalysisList]);
  };

  const DISPLAY_STEPS = [0, 1];

  useEffect(() => {
    (async () => {
      const rawUpn = props.upn.replace(".", "").replace("/", "");
      const errors: number[] = [];
      setIsLoading(true);
      const promises = DISPLAY_STEPS.map(async (page) => {
        try {
          const data = await getCriteriaAnalysis(rawUpn, page);

          return data;
        } catch (error) {
          errors.push(page + 1);

          return {
            status: Status.NotOk,
            compliancePotential: 0,
            criteria: [],
            analysisRequested: false,
          } as unknown as CriteriaAnalysisResponse;
        }
      });

      const analysisData = await Promise.all(promises);

      setCriteriaAnalysisData(analysisData);
      setPageErrors([...errors]);
      setIsLoading(false);
    })();
  }, [props.upn, updateData]);

  useEffect(() => {
    setPage(0);
  }, [props.upn]);

  const onStatusChange = () => {
    setUpdateData(updateData + 1);
  };

  return isLoading ? (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Wrapper ref={screenRef}>
      <StepsDisplayer criteriaData={criteriaAnalysisData} setPage={setPage} />
      <ComplianceResult
        criteriaPage={page}
        currentCriteriaAnalysisData={currentCriteriaAnalysisData}
        screenshooter={props.screenshooter}
        screenRef={screenRef}
        plg={props.plg}
        key={JSON.stringify(currentCriteriaAnalysisData)}
      />
      <CriteriaResult
        criteriaAnalysisData={criteriaAnalysisData}
        setAnalysisRequested={setAnalysisRequested}
        criteriaPage={page}
        currentCriteriaAnalysisData={currentCriteriaAnalysisData}
        upn={props.upn}
        onStatusChange={onStatusChange}
      />
      <FooterInfo
        updatedAt={currentCriteriaAnalysisData.updatedAt}
        updatedBy={currentCriteriaAnalysisData.updatedBy}
        requestedAt={currentCriteriaAnalysisData.requestedAt}
        requestedBy={currentCriteriaAnalysisData.requestedBy}
      />

      <Snackbar
        open={showErrors && pageErrors.length > 0}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <MuiAlert
          onClose={() => setShowErrors(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          <>{messageError}</>
        </MuiAlert>
      </Snackbar>
    </Wrapper>
  );
};

export default memo(CriteriaAnalysis);
