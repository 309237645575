import React, { useEffect, useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import * as S from "../styles";
import { ReactComponent as InfoIcon } from "assets/image/icons/info/info.svg";
import { Status } from "types/data-access";
import { CriteriaData } from "types/data-access";
import {
  Grid,
  SelectChangeEvent,
  Typography,
  Box,
  Input,
  IconButton,
  styled,
} from "@mui/material";

import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import Select from "components/inputs/Select";
import { statusText } from "../info";
import useConfig from "services/config/useConfig";
import { wordBreak } from "html2canvas/dist/types/css/property-descriptors/word-break";

type Props = {
  item: string;
  description: string;
  isValidating: boolean;
  criteriaPage: number;
  criteriaData: CriteriaData[];
  newCriteria: CriteriaData[];
  setNewCriteria: (c: CriteriaData[]) => void;
  shouldSave: boolean;
  labels?: {
    [s: string]: string;
  };
  criteriaKey: string;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    border: '1px solid #dadde9',
  },
}));

const CriteriaItem = (props: Props) => {
  const { translation, language } = useConfig();

  const [isNoteVisible, setNoteVisibility] = useState(false);
  const [criteriaData, setCriteriaData] = useState<CriteriaData | undefined>(
    undefined
  );

  const result: CriteriaData | undefined = (props.newCriteria || []).find(
    (i) => i.label === props.item
  );
  const labels = props.labels ?? statusText;
  const status: Status = result?.status as Status;
  const showReason =
    status === Status.NotOk || criteriaData?.status === Status.NotOk;

  useEffect(() => {
    setCriteriaData(result);
  }, [result]);

  const translateStatus = (status: string) => {
    const statusTranslation = Object.values(translation.system.status).find(
      (s) => s?.["pt-br"].toLocaleLowerCase() === status?.toLocaleLowerCase()
    );

    return statusTranslation ? statusTranslation[language] : status;
  };

  const translateItem = (key: string) => {
    const criteriaEntry = Object.entries(
      translation.packageCriteria[
        `package${props.criteriaPage + 1}` as
        | "package1"
        | "package2"
        | "package3"
      ].criteria
    ).find(([criteriaKey]) => criteriaKey === key);


    if (criteriaEntry) {
      const [_k, data] = criteriaEntry;

      return data;
    }

    return null;
  };

  const selectOptions = [
    { value: Status.Ok, label: translateStatus(labels[Status.Ok]) },
    {
      value: Status.NotOk,
      label: translateStatus(labels[Status.NotOk]),
    },
    {
      value: Status.Pending,
      label: translateStatus(labels[Status.Pending]),
    },
  ];

  const onChange = (e: SelectChangeEvent<any>) => {
    if (criteriaData) {
      setCriteriaData({ ...criteriaData, status: e.target.value });
    }
  };

  useEffect(() => {
    if (props.shouldSave) {
      const remadeCriteria = props.newCriteria.map((i) => {
        if (i.label === props.item) {
          i.status = criteriaData?.status;
          i.note = criteriaData?.note || "";
        }
        return i;
      });
      props.setNewCriteria(remadeCriteria);
    }
  }, [props.shouldSave]);

  const onChangeNote = (e: any) => {
    if (criteriaData) {
      setCriteriaData({ ...criteriaData, note: e.target.value });
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1vmin",
        }}
      >
        <HtmlTooltip
          title={
            translateItem(props.criteriaKey)?.description[language] ||
            props.description
          }
        >
          <Typography
            sx={{
              cursor: "help",
              display: "flex",
              alignItems: "center",
              gap: ".5vmin",
              flexGrow: 1,
              width: "90%",
              flexShrink: "1",
              fontSize: ".875rem",
              wordWrap: "break-word",
            }}
          >
            {translateItem(props.criteriaKey)?.title[language] || props.item}
            <InfoIcon></InfoIcon>
          </Typography>
        </HtmlTooltip>

        {result?.note && status === Status.NotOk && !props.isValidating && (
          <IconButton onClick={() => setNoteVisibility(!isNoteVisible)}>
            {isNoteVisible && <KeyboardArrowDown></KeyboardArrowDown>}
            {!isNoteVisible && <KeyboardArrowUp></KeyboardArrowUp>}
          </IconButton>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            minWidth: "150px",
            marginLeft: "auto",
          }}
        >
          {props.isValidating && props.criteriaPage !== 0 ? (
            <Select
              value={criteriaData?.status}
              onChange={onChange}
              sx={{
                marginLeft: "auto",
                width: "100%",
                fontSize: "0.75rem",
                height: "2.5rem",
              }}
              items={selectOptions}
            />
          ) : (
            <S.StyledButton
              sx={{
                marginLeft: "auto",
                padding: ".5rem 0.25rem",
                height: "2.5rem",
                fontSize: "0.75rem",
                width: "100%",
              }}
              variant="contained"
              status={status}
              disabled
            >
              {translateStatus(labels[status])}
            </S.StyledButton>
          )}
        </Box>
      </Box>

      <Box sx={{ marginTop: "1vmin" }}>
        {showReason &&
          (props.isValidating ? (
            <Input
              multiline={true}
              placeholder="Motivo"
              style={{ width: "100%" }}
              value={criteriaData?.note || ""}
              onChange={onChangeNote}
            />
          ) : isNoteVisible ? (
            <span>{result?.note}</span>
          ) : (
            <></>
          ))}
      </Box>
    </Grid>
  );
};

export default CriteriaItem;
