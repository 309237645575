import React, { PropsWithChildren, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Getters } from "store/auth";

const RouteGuard = ({ children }: PropsWithChildren<{}>) => {
  const isAuthenticated = useSelector(Getters.isAuthenticate);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname } = location;

    const isAuthRoute = ["login", "register", "recover-password", ""].includes(
      pathname.replace(/^\/*/, "")
    );

    if (!isAuthenticated && !isAuthRoute) {
      navigate("/login");
    }
  }, [location, isAuthenticated]);

  return <>{children}</>;
};

export default RouteGuard;
