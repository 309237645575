import styled from "styled-components";
import * as T from "components/layout/titles";

export const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  box-shadow: 24;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  color: white;
  background: #da9121;
  width: 100%;
`;

export const SubHeader = styled.div`
  width: 100%;
  padding: 0.5rem 3rem;
  background-color: transparent;

  span + span {
    margin-left: 20px;
  }

  span {
    &:first-child {
      font-weight: 700;
      font-size: 16px;
    }

    &:nth-child(2) {
      font-weight: 500;
      font-size: 16px;
    }
  }
`;

export const Wrapper = styled.span`
  padding: 1rem 3rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
  row-gap: 30px;
  column-gap: 20%;
`;

export const Content = styled.div`
  overflow: auto;
  flex-grow: 1;
  display: flex;
`;

export const StyledTitle = styled(T.SectionTitle)`
  margin: 20px 0;
`;

type TabDataProps = {
  open: boolean;
};

export const TabData = styled.div<TabDataProps>`
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: "column";
  width: 100%;
`;
