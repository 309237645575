import React from "react";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { OutlinedInputProps } from "@mui/material/OutlinedInput";

import * as S from "./styles";

type Props = {
  label?: string;
  error?: boolean;
  placeholder?: string;
  messageError?: string;
  type?: string;
  render?: React.FC<OutlinedInputProps & Props>;
};

const Textfield = (props: Props & OutlinedInputProps) => {
  const InputComponent = props.render ? props.render : S.Input;

  const { label, error, ...parsedProps } = props;

  return (
    <FormControl sx={{ m: 1 }} variant="outlined">
      <S.InputText>{props.label}</S.InputText>
      <InputComponent
        {...parsedProps}
        type={props.type}
        error={props.error}
        placeholder={props.placeholder}
        endAdornment={
          props.error && (
            <InputAdornment position="end">
              <IconButton edge="end">{<S.ErrorIcon />}</IconButton>
            </InputAdornment>
          )
        }
      />
      <S.InputText error>{props.error && props.messageError}</S.InputText>
    </FormControl>
  );
};

export default Textfield;
