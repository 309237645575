import React from "react";
import { View, Document, Image, Text } from "@react-pdf/renderer";
import { CriteriaAnalysisResponse, CriteriaData } from "types/data-access";
import { pageStyle, criteriaStyle, displayerStyle, mapStyle } from "../styles";
import { Status } from "types/data-access";
import { statusText } from "../../../CriteriaResult/info";
import DefaultPage from "../Default";
import { Languages } from "types/config/i18n";
import * as translation from "services/config/i18n";

type Props = {
  mapImage: string;
  upn: string;
  currentCriteriaAnalysisData: CriteriaAnalysisResponse;
  plgName: string;
  phase: string;
  status: string;
  potential: number;
  displayerImage: string;
  displayerTitle: string;
  displayerDescription: string;
  displayerImageText: string;
  language: Languages;
};

const criteriaKeys = [
  "RAL",
  "EnvironmentalConstraints",
  "RecuperationPlan",
  "OperatingLicense",
  "TailingsTreatment",
  "ProximityLocalCommunities",
  "NoErraticOperationNearby",
];

const MAX_LINES_WITH_MAP = 9;

const Layout = (props: Props) => {
  const {
    mapImage,
    upn,
    currentCriteriaAnalysisData,
    plgName,
    phase,
    status,
    displayerDescription,
    displayerImage,
    displayerImageText,
    displayerTitle,
    language,
  } = props;

  const translateComplianceResult = (
    text: string,
    field: "title" | "description"
  ) => {
    const { package1, package2, package3 } = translation.packageCriteria;
    const translationData = [
      package1.complianceResult[field],
      package2.complianceResult[field],
      package3.complianceResult[field],
    ];

    return (
      translationData.find((d) => d[Languages.PORTUGUESE] === text)?.[
      language
      ] || text
    );
  };

  const translateCriteriaStatus = (status: string) => {
    const statusTranslation = Object.values(translation.system.status).find(
      (s) => s?.["pt-br"].toLocaleLowerCase() === status?.toLocaleLowerCase()
    );

    return statusTranslation ? statusTranslation[language] : status;
  };

  const translateCriteria = (key: string) => {
    const { package1, package2, package3 } = translation.packageCriteria;
    const packages = {
      ...package1.criteria,
      ...package2.criteria,
      ...package3.criteria,
    };

    const translationData = Object.entries(packages).find(([k]) => k === key);

    if (translationData) {
      return translationData[1].title[language];
    }

    return "";
  };

  const criteriaList = currentCriteriaAnalysisData.criteria.filter((criteria) =>
    criteriaKeys.includes(criteria.key as string)
  );
  const producerList = currentCriteriaAnalysisData.criteria.filter(
    (criteria) => !criteriaKeys.includes(criteria.key as string)
  );

  const countLines = (criteria: CriteriaData[]) =>
    criteria.reduce(
      (acc, curr, index) => {
        const currentCount = [...acc];
        const totalLines = Math.ceil(curr.note.length / 57);
        const lines = totalLines <= 3 ? totalLines : 3;

        if (index % 2 === 0) {
          currentCount[1] += lines + 1;
        } else {
          currentCount[0] += lines + 1;
        }

        return [...currentCount];
      },
      [0, 0]
    );

  const getBigger = (columnsLines: number[]) => {
    const [left, right] = columnsLines;

    return left > right ? left : right;
  };

  const criteriaListLines = getBigger(countLines(criteriaList));
  const producerListLines = getBigger(countLines(producerList));
  const totalLines = criteriaListLines + producerListLines;
  const placeMapSamePage = totalLines <= MAX_LINES_WITH_MAP;

  const mountComponent = (criteria: CriteriaData) => {
    const buttonColor =
      criteria.status === Status.Ok
        ? "#79AF65"
        : criteria.status === Status.NotOk
          ? "#FF8080"
          : "#A4A4AD";

    return (
      <View style={criteriaStyle.itemWrapper}>
        <View style={criteriaStyle.item}>
          <View
            style={[criteriaStyle.itemButton, { backgroundColor: buttonColor }]}
          >
            <Text>
              {translateCriteriaStatus(statusText[criteria.status as Status])}
            </Text>
          </View>
          <View style={criteriaStyle.itemText}>
            <Text>
              {translateCriteria(criteria.key || "") || criteria.label}
            </Text>
          </View>
        </View>

        <View style={criteriaStyle.itemNote}>
          <Text>
            {(criteria.status as Status) === Status.NotOk ? criteria.note : ""}
          </Text>
        </View>
      </View>
    );
  };
  const CriteriaItems = criteriaList.map(mountComponent);
  const ProducerItems = producerList.map(mountComponent);

  return (
    <Document>
      <DefaultPage
        upn={upn}
        plgName={plgName}
        phase={phase}
        status={status}
        language={language}
      >
        <View style={displayerStyle.wrapper}>
          <View style={displayerStyle.imageWrapper}>
            <Image style={displayerStyle.image} src={displayerImage}></Image>
            <Text style={displayerStyle.imageText}>{displayerImageText}</Text>
          </View>

          <View style={displayerStyle.textWrapper}>
            <View style={displayerStyle.title}>
              <Text>{translateComplianceResult(displayerTitle, "title")}</Text>
            </View>
            <View style={displayerStyle.description}>
              <Text>
                {translateComplianceResult(displayerDescription, "description")}
              </Text>
            </View>
          </View>
        </View>

        <View style={criteriaStyle.root}>
          <View style={criteriaStyle.title}>
            <Text>
              {translation.packageCriteria.producerDocuments[language]}
            </Text>
          </View>
          <View style={criteriaStyle.itemsWrapper}>{ProducerItems}</View>
        </View>

        <View style={pageStyle.space} />

        <View style={criteriaStyle.root}>
          <View style={criteriaStyle.title}>
            <Text>
              {translation.packageCriteria.analysisCriteria[language]}
            </Text>
          </View>
          <View style={criteriaStyle.itemsWrapper}>{CriteriaItems}</View>
        </View>

        {placeMapSamePage && (
          <View style={[mapStyle.wrapper]}>
            <Image
              style={[
                mapStyle.image,
                {
                  width: `${1.875 * (8 - 0.35 * totalLines)}cm`,
                  height: `${1 * (8 - 0.35 * totalLines)}cm`,
                },
              ]}
              src={mapImage}
            ></Image>
          </View>
        )}
      </DefaultPage>

      {!placeMapSamePage && (
        <DefaultPage
          upn={upn}
          plgName={plgName}
          phase={phase}
          status={status}
          language={language}
        >
          <View style={[mapStyle.wrapper, { marginTop: "2cm" }]}>
            <Image
              style={[mapStyle.image, { width: "100%", height: "10cm" }]}
              src={mapImage}
            ></Image>
          </View>
        </DefaultPage>
      )}
    </Document>
  );
};

export default Layout;
