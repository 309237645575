import { Languages } from "types/config/i18n";

export const auth = {
  list: {
    title: {
      main: {
        [Languages.PORTUGUESE]: "Lista de usuários",
        [Languages.ENGLISH]: "User list",
      },
    },
  },
  register: {
    title: {
      main: {
        [Languages.PORTUGUESE]: "Cadastrar um novo usuário",
        [Languages.ENGLISH]: "Register a new user",
      },
    },
    form: {
      title: {
        name: {
          [Languages.PORTUGUESE]: "Nome",
          [Languages.ENGLISH]: "Name",
        },
        email: {
          [Languages.PORTUGUESE]: "E-mail",
          [Languages.ENGLISH]: "E-mail address",
        },
        password: {
          [Languages.PORTUGUESE]: "Senha",
          [Languages.ENGLISH]: "Password",
        },
      },
      placeholder: {
        name: {
          [Languages.PORTUGUESE]: "Digite aqui seu nome",
          [Languages.ENGLISH]: "Enter your name",
        },
        email: {
          [Languages.PORTUGUESE]: "Digite aqui seu e-mail",
          [Languages.ENGLISH]: "Enter your e-mail address",
        },
        profile: {
          [Languages.PORTUGUESE]: "Selecione aqui o perfil do usuário",
          [Languages.ENGLISH]: "Select your user profile",
        },
        password: {
          [Languages.PORTUGUESE]: "Digite aqui sua senha",
          [Languages.ENGLISH]: "Enter your password",
        },
      },
      values: {
        profile: {
          buyer: {
            [Languages.PORTUGUESE]: "Comprador",
            [Languages.ENGLISH]: "Buyer",
          },
          producer: {
            [Languages.PORTUGUESE]: "Produtor",
            [Languages.ENGLISH]: "Producer",
          },
          technician: {
            [Languages.PORTUGUESE]: "Técnico",
            [Languages.ENGLISH]: "Technician",
          },
        },
      },
    },
  },
  login: {
    title: {
      main: {
        [Languages.PORTUGUESE]: "Faça Login",
        [Languages.ENGLISH]: "Log in",
      },
    },
    form: {
      title: {
        email: {
          [Languages.PORTUGUESE]: "E-mail",
          [Languages.ENGLISH]: "E-mail address",
        },
        password: {
          [Languages.PORTUGUESE]: "Senha",
          [Languages.ENGLISH]: "Password",
        },
      },
      placeholder: {
        email: {
          [Languages.PORTUGUESE]: "Digite aqui seu e-mail",
          [Languages.ENGLISH]: "Enter your e-mail address",
        },
        password: {
          [Languages.PORTUGUESE]: "Digite aqui sua senha",
          [Languages.ENGLISH]: "Enter your password",
        },
      },
    },
    hyperlink: {
      forgotPassword: {
        [Languages.PORTUGUESE]: "Esqueceu a senha?",
        [Languages.ENGLISH]: "Forgot your password?",
      },
      signup: {
        [Languages.PORTUGUESE]: "Cadastre-se aqui gratuitamente",
        [Languages.ENGLISH]: "Sign up for a free account",
      },
    },
  },
  resetPassword: {
    title: {
      main: {
        [Languages.PORTUGUESE]: "Recuperar senha",
        [Languages.ENGLISH]: "Reset your password",
      },
      subtitle: {
        [Languages.PORTUGUESE]:
          "Por favor, insira seu e-mail abaixo e nós enviaremos um link para você redefinir sua senha.",
        [Languages.ENGLISH]:
          "Please enter your e-mail address and we will send you a link to reset your password.",
      },
      emailSent: {
        [Languages.PORTUGUESE]: "Email enviado",
        [Languages.ENGLISH]: "Email sent",
      },
      emailSentSubtitle: {
        [Languages.PORTUGUESE]: "Pronto!",
        [Languages.ENGLISH]: "",
      },
    },
    message: {
      success: {
        [Languages.PORTUGUESE]:
          "Instruções para resetar sua senha foram enviadas para:",
        [Languages.ENGLISH]:
          "Instructions for resetting your password sent to:",
      },
    },
    form: {
      placeholder: {
        email: {
          [Languages.PORTUGUESE]: "Digite aqui seu e-mail",
          [Languages.ENGLISH]: "Enter your e-mail address",
        },
      },
    },
  },
};
