import React, { RefObject } from "react";
import useConfig from "services/config/useConfig";

import { Status } from "types/data-access";
import { chooseDisplayer, chooseText } from "../../status-icon";
import * as S from "../styles";

type Props = {
  status?: Status;
  compliancePotential?: number;
  criteriaPage: number;
  displayerRef: RefObject<HTMLDivElement>;
  setText: (v: string) => void;
};

const Displayer = (props: Props) => {
  const { translation, language } = useConfig();

  const translateStatus = (status: string) => {
    const statusTranslation = Object.values(
      translation.packageCriteria.compliance.status
    ).find(
      (s) => s?.["pt-br"].toLocaleLowerCase() === status?.toLocaleLowerCase()
    );

    return statusTranslation ? statusTranslation[language] : status;
  };

  const handleChooseText = (
    criteriaPage: number,
    percentage?: number,
    status?: Status
  ) => {
    const text = chooseText(criteriaPage, percentage || 0, status);
    const translatedText = translateStatus(text || "");
    props.setText(translatedText || "");

    return translatedText;
  };

  return (
    <S.ResultIcon hasGauge={props.criteriaPage === 2}>
      <div ref={props.displayerRef}>
        {chooseDisplayer(
          props.criteriaPage,
          props.compliancePotential,
          props.status
        )}
      </div>

      <span>
        {handleChooseText(
          props.criteriaPage,
          props.compliancePotential,
          props.status
        )}
      </span>
    </S.ResultIcon>
  );
};

export default Displayer;
