import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, AppBar, Toolbar } from "@mui/material";
import Logo from "assets/image/logo/Logo.png";

export const NAVBAR_HEIGHT = "70px";

const BaseHeader = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      sx={{
        background: "",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ background: "#414142", height: NAVBAR_HEIGHT, borderTop: "4px solid #fccb5b" }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          maxWidth: "1300px",
          width: "100%",
          marginLeft: "auto",
          marginRight: "auto",
        }}>
          <Box component={Button} sx={{

          }} onClick={() => navigate("/")}>
            <Box
              component="img"
              src={Logo}
              sx={{
                width: "80px",
              }}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {children}
          </Box>
        </Box>

      </Toolbar>
    </AppBar>
  );
};

export default BaseHeader;
