import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { FC, memo, PropsWithoutRef, useEffect, useState } from "react";
import Button from "components/button/DefaultButton";
import { usePlgs } from "./hooks";
import useConfig from "services/config/useConfig";

export type Props = PropsWithoutRef<{
  filter: string;
  criteria: string[];
  onSelect?: (upn: string) => void;
  setModalIsOpen: (v: boolean) => void;
}>;

export interface Result {
  upn: string;
}

const FilterResult: FC<Props> = ({
  filter: filterBy,
  criteria,
  onSelect,
  setModalIsOpen,
}: Props) => {
  const { plgs, searchNewPlgs, handleScroll, count } = usePlgs({
    criteria,
  });

  const [isLoading, setLoading] = useState(false);


  const { translation, language } = useConfig();

  const onClick = (plg: Result) => () => {
    if (onSelect) {
      onSelect(plg.upn);
      setTimeout(() => {
        setModalIsOpen(true);
      }, 1500);
    }
  };

  useEffect(() => {
    setLoading(true);
    searchNewPlgs().then(() => setLoading(false));
  }, [criteria]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "50vh",
        width: "100%",
        position: "relative",
        overflow: "hidden",
        mt: 2,
        mb: 2,
      }}
    >
      {isLoading && <Typography>Carregando...</Typography>}
      {!isLoading ? plgs?.length ? (
        <Typography sx={{ mb: 1, fontWeight: "bold", display: "flex", gap: "4px", alignItems: "center" }}>
          {translation.map.search.filter.resultTitle[language]}
          <div style={isLoading ? { color: "lightgray", background: "lightgray", opacity: 0.4 } : {}}>({count})</div>:
        </Typography>
      ) : (
        <Typography sx={{ mb: 1, fontWeight: "bold" }}>
          {translation.map.search.filter.noResults[language]}
        </Typography>
      ) : <></>}

      <List
        sx={{
          pl: 1,
          flexGrow: "1",
          pt: 0,
          overflowY: "auto",
          maxHeight: "500px",
        }}
        onScroll={handleScroll}
      >
        {plgs?.map((plg, index) => {
          return (
            <ListItem sx={{ p: 0 }} key={index}>
              <ListItemText
                primaryTypographyProps={{
                  color: "#da9121",
                  fontWeight: "medium",
                }}
                secondaryTypographyProps={{
                  color: "#4B5768",
                  fontWeight: "medium",
                }}
                primary={translation.system.case[language]}
                secondary={plg?.upn}
              ></ListItemText>

              <Button variant="contained" onClick={onClick(plg)} disabled={isLoading} sx={{
                ":disabled": {
                  color: "black",
                  background: "transparent",
                  opacity: 0.2,
                }
              }}>
                {translation.buttons.map.seeAnalysis[language]}
              </Button>

            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default memo(FilterResult);
