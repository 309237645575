import L from "leaflet";
import _ from "lodash";

/**
 * Cleans any string and generates a string
 * containing only digits.
 *
 * @param s
 * @returns
 */

export const getRawUpn = (upn: string | undefined) =>
  (upn ?? "").replace(/[^\d]/g, "").trim();

export const formatUpn = (upn: string | undefined) => {
  const rawUpn = getRawUpn(upn);

  return `${rawUpn.slice(0, 3)}.${rawUpn.slice(3, 6)}/${rawUpn.slice(-4)}`;
};

const bboxRegex = /(-?\d{1,100}\.\d{1,100})/gi;

export const bbox2LatLngBounds = (bbox: string): L.LatLngBounds | null => {
  const box = ((bbox ?? "").match(bboxRegex) ?? []).map(parseFloat);

  if (box.length) {
    const bounds = _.chunk(box, 2);

    const ne = new L.LatLng(bounds[0][1], bounds[0][0]);
    const sw = new L.LatLng(
      bounds[bounds.length - 2][1],
      bounds[bounds.length - 2][0]
    );

    return new L.LatLngBounds(ne, sw);
  } else {
    return null;
  }
};
