import React, { useState } from "react";
import useConfig from "services/config/useConfig";
import { Grid, Icon, IconButton, Typography } from "@mui/material";

const Hero = () => {
  const { t, language } = useConfig();

  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(!open);

  return <Grid container gap={2} sx={{ py: "2rem" }}>
    <Grid item xs={12} md={7}>
      <Typography sx={{
        fontSize: "2rem",
        fontWeight: "900",
        marginBottom: "2rem",
        color: "#da9121",
      }}>{t.home.carousel.title[language]}</Typography>
      <Typography sx={{ minHeight: '120px', fontWeight: 500, whiteSpace: "pre-wrap" }}>{open ? t.home.carousel.subtitle2[language] : t.home.carousel.subtitle[language]}</Typography>
      <IconButton sx={{ marginTop: '1rem', border: '2px solid black', color: 'black', p: 0 }} onClick={handleClick}>
        <Icon>{open ? "chevron_left" : "chevron_right"}</Icon>
      </IconButton>
    </Grid>
  </Grid>
};

export default Hero;
