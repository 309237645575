import { useDispatch, useSelector } from "react-redux";
import { Getters, setLanguage } from "store/config";
import { Languages } from "types/config/i18n";
import * as translation from "./i18n";

const useConfig = () => {
  const dispatch = useDispatch();

  const language = useSelector(Getters.language);

  const handleSetLanguage = (lang: Languages) => {
    dispatch(setLanguage(lang));
  };

  return {
    setLanguage: handleSetLanguage,
    language,
    translation,
    t: translation,
  };
};

export default useConfig;
