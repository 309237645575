import { client } from "../client";

import { Profile } from "types/auth";
import { AxiosResponse } from "axios";

export type RegisterDto = {
  name: string;
  email: string;
  password: string;
  profile: Profile;
};

type AuthResponseAPI = {
  access_token: string;
  user_name: string;
  user_profile: Profile;
};

type AuthResponse = {
  accessToken: string;
  userName: string;
  userProfile: Profile;
};

type ResetPasswordResponse = {
  email: string;
  success: boolean;
};

export const register = async (data: RegisterDto): Promise<{ user: any }> => {
  const response: AxiosResponse<AuthResponseAPI> = await client.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/register`,
    data
  );

  const user = response.data;

  return { user };
};

export type LoginDto = {
  email: string;
  password: string;
};

export const login = async (data: LoginDto): Promise<AuthResponse> => {
  const response: AxiosResponse<AuthResponseAPI> = await client.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
    data
  );

  return {
    accessToken: response.data.access_token,
    userName: response.data.user_name,
    userProfile: response.data.user_profile,
  };
};

export const resetPassword = async (email: string) => {
  const response: AxiosResponse<ResetPasswordResponse> = await client.get(
    `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`,
    { params: { email } }
  );

  return response.data;
};

type UpdatePasswordDto = {
  password: string;
  token: string;
};

export const updatePassword = async (data: UpdatePasswordDto) => {
  await client.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`,
    data
  );
};

export const checkAuthToken = async () => {
  try {
    await client.get("/auth/check");
    return true;
  } catch (error) {
    return false;
  }
};
