import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { Profile } from "types/auth";
import { Languages } from "types/config/i18n";

export type State = {
  language: Languages;
};

export const authSlice = createSlice({
  name: "config",
  initialState: {
    language: Languages.PORTUGUESE,
  } as State,
  reducers: {
    setLanguage: (state, action: PayloadAction<Languages>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = authSlice.actions;

export const Getters = {
  language: (store: RootState) => store.config.language,
};

export default authSlice.reducer;
