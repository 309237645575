import { Languages } from "types/config/i18n";

type Criteria = {
  [key: string]: {
    title: {
      [language: string]: string;
    };
    description: {
      [language: string]: string;
    };
  };
};

type PackageData = {
  complianceResult: {
    title: {
      [language: string]: string;
    };
    description: {
      [language: string]: string;
    };
  };
  criteria: Criteria;
};

export const package1: PackageData = {
  complianceResult: {
    title: {
      [Languages.PORTUGUESE]: "Pacote mínimo de transparência",
      [Languages.ENGLISH]: "Minimal transparency package",
    },
    description: {
      [Languages.PORTUGUESE]:
        "O primeiro nível de análise do PTO avalia os critérios básicos da conformidade de um processo para extração mineral, nesse pacote a análise é realizada de forma automatizada usando o banco de dados da Agência Nacional de Mineração, dentre outros. A conformidade do processo nessa fase garantirá que está de acordo com os critérios mínimos para a mineração, entretanto, há outros riscos que devem ser analisados caso a caso por um técnico especialista nos Pacotes 2 e 3.",
      [Languages.ENGLISH]:
        "The first level of analysis of the PTO evaluates the essential criteria of compliance of a case for mineral extraction. In this package, analysis is carried out in an automated way using the database of the National Mining Agency, among others. Compliance with the case at this stage will ensure that it complies with the minimum criteria for mining. However, other risks should be analyzed on a case-by-case basis by an expert in Packages 2 and 3.",
    },
  },
  criteria: {
    SuitableSituationForMining: {
      title: {
        [Languages.PORTUGUESE]: "Em situação adequada para mineração",
        [Languages.ENGLISH]: "Suitable situation for mining",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Apenas processos Ativos estão aptos para extração mineral. (Um processo ativo é considerado apto para ser analisado, sua regularidade não deve ser definida tão somente por esse critério, entretanto processos inativos obrigatoriamente não devem possuir extração mineral em execução.)",
        [Languages.ENGLISH]:
          "Only active processes are suitable for mineral extraction. (An active process is considered fit to be analyzed, its regularity should not be defined only by this criterion alone; however, inactive processes must not have active mineral extraction.)",
      },
    },
    TIIntersection: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal fora de Terra indígena",
        [Languages.ENGLISH]: "Polygon outside of an Indigenous Land",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos com sobreposição a área de restrição são considerados ilegais para extração mineral. (A Constituição Federal estabelece no art. 231, §3º que a lavra das riquezas minerais em terras indígenas só pode ser efetivada com autorização do Congresso Nacional, ouvidas as comunidades afetadas, ficando-lhes assegurada participação nos resultados da lavra, na forma da lei. Na ausência de lei regulamentando a matéria, é vedada a mineração em terras indígenas.)",
        [Languages.ENGLISH]:
          "Processes that overlap the restriction area are considered illegal for mineral extraction. (The Brazilian Federal Constitution establishes in Art 231, §3 that mining mineral wealth in indigenous lands can only be effected with authorization from the National Congress after hearing the affected communities and being assured their participation in the results of the mining. Without legislaiton regulating the matter, mining on indigenous lands is prohibited.)",
      },
    },
    UCIntersection: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal fora de Unidades de conservação",
        [Languages.ENGLISH]: "Polygon outside of a Conservation Unit",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos com sobreposição a área de restrição são considerados ilegais para extração mineral, como o caso de processos em unidades de proteção integral e Unidades de uso sustentável",
        [Languages.ENGLISH]:
          "Processes overlapping the restriction area are considered illegal for mineral extraction. This is the case of cases in integral protection units and sustainable use units.",
      },
    },
    ValidTitle: {
      title: {
        [Languages.PORTUGUESE]: "Título válido",
        [Languages.ENGLISH]: "Valid Title",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Apenas processos com o Título ativo na categoria Permissão de Lavra Garimpeira, Portaria de lavra ou Registro de Grupamento Mineiro, são considerados aptos para mineração.",
        [Languages.ENGLISH]:
          "Only processes with the Active Title in the category Small-scale Independent Mining Permits (Permissão de Lavra Garimpeira), Mining Ordinance (Portaria de Lavra), or Register of a Mining Group (Grupamento Mineiro) are considered suitable for mining.",
      },
    },
    ProtocoledEnvLicensing: {
      title: {
        [Languages.PORTUGUESE]: "Licenciamento ambiental protocolado",
        [Languages.ENGLISH]: "Environmental licensing filed",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos sem licença ambiental ou com licença ambiental expirada estão irregulares para mineração. (Todo empreendimento minerário implica, para o empreendedor, a obrigação de prévio licenciamento ambiental e de reparação do dano ao meio ambiente por ele causado, também por força de norma constitucional (art. 225, §1o, IV, e §2º), reforçada por previsões da Lei da Política Nacional do Meio Ambiente (Lei n. 6.938/1981) e das Resoluções n. 01/1986, n. 09/1990 e n. 237/1997 do Conselho Nacional de Meio Ambiente.)\n\nPara cada estado o tempo da licença ambiental varia, abaixo você pode consultar o tempo de validade da licença ambiental para cada estado (em anos):\n\nAM: 5, AC: 10, PA: 4, RR: 10, RO: 5, MT: 3, MS: 4, GO: 6, TO: 10, MA: 4, RS: 4, PR: 6, SC: 6, MG: 6, AL: 10, AP: 6, BA: 8, CE: 4, ES: 6, PE: 10, PI: 10, RJ: 10, RN: 6, SP: 10, SE: 5, DF: 4, PB: 2",
        [Languages.ENGLISH]:
          "Cases without an environmental license or with a license filed more than three years prior are irregular for mining. (Every mining undertaking implies, for the entrepreneur, the obligation of prior environmental licensing and repair of the damage to the environment caused by it according to constitutional rule (Art. 225, §1o, IV, and §2º), reinforced by the legislation set for in the National Environmental Policy (Law # 6,938/1981) and Resolutions # 01/1986, 09/1990 and 237/1997 of the National Council on the Environment)\n\nFor each state, the environmental license validity period varies. Below you can check the validity period of the environmental license for each state (in years):\n\nAM: 5, AC: 10, PA: 4, RR: 10, RO: 5, MT: 3, MS: 4, GO: 6, TO: 10, MA: 4, RS: 4, PR: 6, SC: 6, MG: 6, AL: 10, AP: 6, BA: 8, CE: 4, ES: 6, PE: 10, PI: 10, RJ: 10, RN: 6, SP: 10, SE: 5, DF: 4, PB: 2",
      },
    },
    CFEM: {
      title: {
        [Languages.PORTUGUESE]: "Pagamento tributário obrigatório (CFEM)",
        [Languages.ENGLISH]: "Mandatory tax payment (CFEM)",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos sem pagamento de CFEM ou com último pagamento há mais de 6 meses estão irregulares para mineração. (Visto que os minerais no subsolo são um bem da União, é obrigatório o pagamento da Compensação Financeira pela Exploração de Recursos Minerais (CFEM), regulada pelas Leis n. 7.990/1989 e 8.001/1990 e modificações)",
        [Languages.ENGLISH]:
          "Cases without an environmental license or with a license filed more than 6 months prior are irregular for mining. (Since underground minerals belong to the Federal Government, it is mandatory to pay financial compensation for the exploitation of mineral resources [CFEM], per Laws # 7.990/1989 and 8.001/1990 and their amendments)",
      },
    },
    SuitablePhaseForMining: {
      title: {
        [Languages.PORTUGUESE]: "Em fase adequada para mineração (Título)",
        [Languages.ENGLISH]: "Suitable phase for mining (Title)",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Apenas processos em fase (título) de Concessão de lavra e Lavra garimpeira estão aptos para extração mineral",
        [Languages.ENGLISH]:
          "Only cases in phase (title) of Mining Concession (Concessao de Lavra) and Small-scale Independent Mining (Lavra Garimpeira) are suitable for mineral extraction",
      },
    },
    RAL: {
      title: {
        [Languages.PORTUGUESE]: "Relatório anual de lavra Protocolado (RAL)",
        [Languages.ENGLISH]: "Annual Mining Report Submmited (RAL)",
      },
      description: {
        [Languages.PORTUGUESE]: "Processos sem pagamento do RAL, estão irregulares para mineração.",
        [Languages.ENGLISH]: "Cases without payment of the RAL are irregular for mining.",
      },
    },
    RALTaxes: {
      title: {
        [Languages.PORTUGUESE]: "Relatório anual de lavra Protocolado (RAL)",
        [Languages.ENGLISH]: "Annual Mining Report Submmited (RAL)",
      },
      description: {
        [Languages.PORTUGUESE]: "Processos sem pagamento do RAL, estão irregulares para mineração.",
        [Languages.ENGLISH]: "Cases without payment of the RAL are irregular for mining.",
      },
    },
    EnvironmentalEmbargo: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal sem Embargo Ambiental (IBAMA)",
        [Languages.ENGLISH]: "Polygon without Environmental Embargo (IBAMA)",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos com sobreposição a área embargada pelo Ibama são considerados irregulares para extração mineral.",
        [Languages.ENGLISH]:
          "Cases overlapping the area embargoed by IBAMA are considered illegal for mineral extraction.",
      },
    },
  },
};

export const package2: PackageData = {
  complianceResult: {
    title: {
      [Languages.PORTUGUESE]: "Pacote intermediário de conformidade PTO",
      [Languages.ENGLISH]: "Intermediate PTO Compliance Package",
    },
    description: {
      [Languages.PORTUGUESE]:
        "Quando o processo é considerado conforme no Pacote 1, ele avançará para o Pacote 2, o qual fará uso do geoprocessamento para verificar a conformidade da operação a este nível por meio da análise de um técnico responsável. Caso se cumpra os pré-requisitos de conformidade estabelecidos neste pacote, ele irá para o Pacote 3, em que serão analisados documentos para definir o seu potencial de conformidade dentro do padrão da Plataforma de Compra Responsável de Ouro.",
      [Languages.ENGLISH]:
        "When the case is considered as per Package 1, it will proceed to Package 2, which will use geoprocessing to verify the operation's compliance at this level through the analysis of a responsible technician. If the compliance prerequisites set out in this package are met, it will proceed to Package 3, where documents are analyzed on its compliance potential within the Responsible Gold Purchasing Platform standard.",
    },
  },
  criteria: {
    AreaOverflowIntersection: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal sem transbordamento de área",
        [Languages.ENGLISH]: "Polygon without area overflow",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Operação de lavra, observada por meio de imagens de satélite, estão fora da poligonal estipulada pela Agência Nacional de Mineração (ANM).",
        [Languages.ENGLISH]:
          "Mining operations, observed through satellite images, are outside the polygon stipulated by the National Mining Agency (ANM).",
      },
    },
    TraditionalTerritoriesIntersection: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal fora de territórios tradicionais",
        [Languages.ENGLISH]: "Polygon outside traditional territories",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos com sobreposição a área de restrição são considerados irregulares para extração mineral.",
        [Languages.ENGLISH]:
          "Cases overlapping restriction areas are considered illegal for mineral extraction.",
      },
    },
    BufferZoneIntersection: {
      title: {
        [Languages.PORTUGUESE]: "Poligonal fora de zona de amortecimento",
        [Languages.ENGLISH]: "Polygon outside buffer zones",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Operação de lavra, observada por meio de imagens de satélite, fora da faixa de amortecimento. Entende-se por faixa de amortecimento áreas localizadas numa faixa de 3 km a partir do limite da UC, com exceção das Unidades de Conservação de Proteção Integral (Resolução Nº 428, de 17 de Dezembro de 2010), com o objetivo de filtrar os impactos negativos das atividades que ocorrem fora dela, como : ruídos, poluição, espécies invasoras e avanço da ocupação humana, especialmente nas unidade próximas às áreas intensamente ocupadas",
        [Languages.ENGLISH]:
          "Mining operations, observed by satellite images, outside the buffer range. A buffer range is defined as an area located within a range of 3 km from the limit of CU, except for Integral Protection Conservation Units (Resolution # 428, Dec 17, 2010). It aims at filtering negative impacts of activities that occur outside the UC such as noise, pollution, invasive species, and human occupation, especially in the units close to the intensely occupied areas",
      },
    },
    GhostFarm: {
      title: {
        [Languages.PORTUGUESE]: "Lavra fantasma",
        [Languages.ENGLISH]: "Ghost Mining",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processo com declaração de produção de ouro, no entanto a área não apresenta caracterização de extração mineral.",
        [Languages.ENGLISH]:
          "Cases with a declaration of gold production. Nevertheless, the area does not have features of mineral extraction.",
      },
    },

    EnvironmentalConservation: {
      title: {
        [Languages.PORTUGUESE]: "Conservação Ambiental",
        [Languages.ENGLISH]: "Environmental Conservation",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Processos com sobreposição a área de Conservação Ambiental são considerados irregulares para extração mineral.",
        [Languages.ENGLISH]:
          "Cases overlapping the restriction area are considered illegal for mineral extraction.",
      },
    },
  },
};

export const package3: PackageData = {
  complianceResult: {
    title: {
      [Languages.PORTUGUESE]: "Potencial de conformidade PTO",
      [Languages.ENGLISH]: "PTO Compliance Potential",
    },
    description: {
      [Languages.PORTUGUESE]:
        "No Pacote 3, o técnico especialista irá analisar as documentações abaixo, que deverão ser encaminhadas pelo titular do processo. O resultado final é obtido na forma de porcentagem para a tomada de decisão do comprador.",
      [Languages.ENGLISH]:
        "In Package 3, the specialist technician will analyze the documentation below, delivered by the case owner. The final result is a percentage to help the buyer's decision-making.",
    },
  },
  criteria: {
    RAL: {
      title: {
        [Languages.PORTUGUESE]: "Relatório Anual de Lavra Protocolado (RAL)",
        [Languages.ENGLISH]: "Annual Mining Report Submitted (RAL)",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Verificação de volume de produção estimada em relação aos valores recolhidos pelo CFEM e imagens de satélite/ortofotos",
        [Languages.ENGLISH]:
          "Verification of estimated production volume concerning the values collected by CFEM and satellite/orthophoto images",
      },
    },
    OperatingLicense: {
      title: {
        [Languages.PORTUGUESE]: "Análise de Licença de Operação (LO)",
        [Languages.ENGLISH]: "Analysis of the License to Operate (LO)",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Verificação das datas de licença e análise junto ao relatório de condicionantes",
        [Languages.ENGLISH]:
          "Verification of license dates and analysis with the constraints report",
      },
    },
    NoErraticOperationNearby: {
      title: {
        [Languages.PORTUGUESE]: "Sem operação irregular nas proximidades",
        [Languages.ENGLISH]: "No irregular operation nearby",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Entende-se por operação irregular nas proximidades, a operação localizada em processo adjacente ao analisado, que não está apto pelo pacote 01",
        [Languages.ENGLISH]:
          "Irregular operation is understood in the vicinity; the operation located in a case adjacent to the analyzed, which is not fit by Package 01",
      },
    },
    ProximityLocalCommunities: {
      title: {
        [Languages.PORTUGUESE]: "Análise de proximidade de comunidades locais",
        [Languages.ENGLISH]: "Analysis of the proximity of local communities",
      },
      description: {
        [Languages.PORTUGUESE]: "Verificação de irregularidades em até 3km ao redor",
        [Languages.ENGLISH]: "Verification of irregularities in up to 3km around",
      },
    },
    TailingsTreatment: {
      title: {
        [Languages.PORTUGUESE]: "Análise de tratamento dos rejeitos",
        [Languages.ENGLISH]: "Analysis of tailings management",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Verificação através de imagem de satélites/ortofotos e relatório de atividades do cumprimento das condicionantes ambientais referentes ao tratamento de rejeitos.",
        [Languages.ENGLISH]:
          "Verification through satellite/orthophotos image and report of activities compliant with environmental constraints related to tailings management.",
      },
    },
    EnvironmentalConstraints: {
      title: {
        [Languages.PORTUGUESE]: "Análise das condicionantes ambientais",
        [Languages.ENGLISH]: "Analysis of environmental constraints",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Verificação através de imagem de satélites/ortofotos e relatório de atividades do cumprimento das condicionantes ambientais",
        [Languages.ENGLISH]:
          "Verification through satellite/orthophotos image and report of activities compliant with environmental constraints",
      },
    },
    RecuperationPlan: {
      title: {
        [Languages.PORTUGUESE]: "Análise de Plano de recuperação",
        [Languages.ENGLISH]: "Recovery Plan Analysis",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Verificação de imagens parar análise de plano de recuperação de áreas degradadas",
        [Languages.ENGLISH]: "Verification of images to analyze the plan for degraded areas",
      },
    },
    CFEMDocument: {
      title: {
        [Languages.PORTUGUESE]: "Comprovante CFEM (último pagamento)",
        [Languages.ENGLISH]: "CFEM receipt (last payment)",
      },
      description: {
        [Languages.PORTUGUESE]: "Guias referente ao pagamento da CFEM",
        [Languages.ENGLISH]: "Receipts regarding CFEM payment",
      },
    },
    RALDocument: {
      title: {
        [Languages.PORTUGUESE]: "Relatório anual de lavra (RAL)",
        [Languages.ENGLISH]: "Annual Mining Report (RAL)",
      },
      description: {
        [Languages.PORTUGUESE]: "Último relatório anual de Lavra protocolado junto a ANM",
        [Languages.ENGLISH]: "Last annual mining report filed within ANM",
      },
    },
    PRADDocument: {
      title: {
        [Languages.PORTUGUESE]: "Plano de recuperação de áreas degradadas (PRAD)",
        [Languages.ENGLISH]: "Degraded Area Recovery Plan (PRAD)",
      },
      description: {
        [Languages.PORTUGUESE]: "Relatório técnico",
        [Languages.ENGLISH]: "Technical report",
      },
    },
    MineClosurePlanDocument: {
      title: {
        [Languages.PORTUGUESE]: "Plano de Fechamento de Mina",
        [Languages.ENGLISH]: "Mine Closure Plan",
      },
      description: {
        [Languages.PORTUGUESE]: "Relatório técnico",
        [Languages.ENGLISH]: "Technical report",
      },
    },
    ARTDocument: {
      title: {
        [Languages.PORTUGUESE]: "ART do responsável Técnico",
        [Languages.ENGLISH]: "ART of the Technical Responsible",
      },
      description: {
        [Languages.PORTUGUESE]: "Anotação de responsabilidade técnica emitida pelo CREA",
        [Languages.ENGLISH]: "Technical responsibility note issued by CREA",
      },
    },
    OperatingLicenseDocument: {
      title: {
        [Languages.PORTUGUESE]: "Licença de operação",
        [Languages.ENGLISH]: "License to Operate (LO)",
      },
      description: {
        [Languages.PORTUGUESE]: "Licença ambiental emitida pelo órgão ambiental estadual.",
        [Languages.ENGLISH]: "Environmental license issued by the state environmental agency",
      },
    },
    LOConditionReportDocument: {
      title: {
        [Languages.PORTUGUESE]: "Relatório de condicionantes da LO",
        [Languages.ENGLISH]: "LO constraints report",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Relatório que apresenta os trabalhos realizados referente às condicionantes determinadas na licença ambiental (LO)",
        [Languages.ENGLISH]:
          "Report presenting the work carried out on the constraints set in the environmental license (LO)",
      },
    },
    DroneDocument: {
      title: {
        [Languages.PORTUGUESE]: "Ortofoto (drone) últimos 6 meses",
        [Languages.ENGLISH]: "Ortophoto (drone) from the prior six months",
      },
      description: {
        [Languages.PORTUGUESE]: "Imagem obtida através de sobrevoo de drone",
        [Languages.ENGLISH]: "Image taken from a flying drone",
      },
    },
  },
};

export const criterias = {
  ...package1.criteria,
  ...package2.criteria,
  ...package3.criteria,
}

export const packageCriteria = {
  analysisCriteria: {
    [Languages.PORTUGUESE]: "Critérios de análise",
    [Languages.ENGLISH]: "Analysis criteria",
  },
  producerDocuments: {
    [Languages.PORTUGUESE]: "Documentos para apresentação do Produtor",
    [Languages.ENGLISH]: "Documents for presentation from the Producer",
  },
  compliance: {
    status: {
      ok: {
        [Languages.PORTUGUESE]: "Apto",
        [Languages.ENGLISH]: "Fit",
      },
      notOk: {
        [Languages.PORTUGUESE]: "Inapto",
        [Languages.ENGLISH]: "Unfit",
      },
      analysisNotReleased: {
        [Languages.PORTUGUESE]: "Análise não liberada",
        [Languages.ENGLISH]: "Analysis not released",
      },
      notReleased: {
        [Languages.PORTUGUESE]: "Não Liberado",
        [Languages.ENGLISH]: "Not released",
      },
      pending: {
        [Languages.PORTUGUESE]: "Pendente de análise",
        [Languages.ENGLISH]: "Pending analysis",
      },
      partial: {
        [Languages.PORTUGUESE]: "Reavaliação",
        [Languages.ENGLISH]: "Reevaluation",
      },
      underAnalysis: {
        [Languages.PORTUGUESE]: "Em análise",
        [Languages.ENGLISH]: "Under analysis",
      },
      low: {
        [Languages.PORTUGUESE]: "Baixo",
        [Languages.ENGLISH]: "Low",
      },
      med: {
        [Languages.PORTUGUESE]: "Médio",
        [Languages.ENGLISH]: "Medium",
      },
      high: {
        [Languages.PORTUGUESE]: "Alto",
        [Languages.ENGLISH]: "High",
      },
    },
  },
  package1,
  package2,
  package3,
  criterias,
};

