import { SxProps } from "@mui/material";

export const headerText: SxProps = {
  fontWeight: "bold",
  fontSize: "1.4rem",
  textTransform: "uppercase",
  marginBottom: "2rem"
};

export const bodyText: SxProps = {
  lineHeight: "150%",
  fontSize: '1rem',
  fontWeight: '500',
  whiteSpace: 'pre-line'
}
