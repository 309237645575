import React, { useState } from "react";
import { FilteredPlg } from "types/data-access";
import { filterPlg } from "http-services/data-access";
import _ from "lodash";

export const usePlgs = ({ criteria }: { criteria?: string[] }) => {
  const [plgs, setPlgs] = useState<FilteredPlg[]>([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const searchPlgs = async () => {
    const { data, count } = await filterPlg({ page, criteria });

    setPlgs(_.uniqBy([...plgs, ...data], 'upn'));
    setCount(count);
    setPage(page + 1);
  };

  const searchNewPlgs = async () => {
    const { data, count } = await filterPlg({ page: 0, criteria });

    setPlgs(data);
    setCount(count);
    setPage(1);
  };

  const handleScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const scrollbarHeight = e.currentTarget.scrollHeight;
    const scrollHeight = e.currentTarget.clientHeight;
    const scrollTopPosition = e.currentTarget.scrollTop;

    const isBottomReached =
      Math.ceil(scrollHeight + scrollTopPosition) === scrollbarHeight;

    if (isBottomReached) {
      searchPlgs();
    }
  };

  return { plgs, searchPlgs, searchNewPlgs, handleScroll, count };
};
