import React from "react";
import Gauge from "react-svg-gauge";
import { Wrapper } from "./styles";

type Props = {
  percentage: number;
};

const setColor = (percentage: number) => {
  if (percentage <= 26.67) {
    return "#D75956";
  }
  if (percentage <= 66.67) {
    return "#F4994F";
  }

  return "#79AF65";
};

const GaugeComponent = ({ percentage }: Props) => {
  return (
    <Wrapper>
      <Gauge
        value={percentage}
        color={setColor(percentage)}
        label={""}
        width={150}
        height={100}
        topLabelStyle={{ display: "none" }}
        minMaxLabelStyle={{ display: "none" }}
        valueLabelStyle={{
          fontSize: "16px",
          stroke: setColor(percentage),
          fill: setColor(percentage),
        }}
        valueFormatter={(n) => `${n}%`}
      />
    </Wrapper>
  );
};

export default GaugeComponent;
