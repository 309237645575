import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoginDto } from "http-services/auth";
import { useAuth } from "services/auth";
import Textfield from "components/inputs/Textfield";
import Button from "components/button/DefaultButton";
import LinkButton from "components/button/LinkButton";
import useConfig from "services/config/useConfig";
import * as S from "../styles";

type Props = {
  goToRegister: () => void;
  goToRecover: () => void;
};

const LoginPage = (props: Props) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const { translation, language } = useConfig();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LoginDto) => {
    await login(data, (message, status) => {
      if (message === "User not found") {
        return setError("email", {
          type: "custom",
          message: translation.errorMessage.form.invalidEmail[language],
        });
      }
      if (status === 401) {
        return setError("password", {
          type: "custom",
          message: translation.errorMessage.form.invalidPassword[language],
        });
      }
      setError("password", {
        type: "custom",
        message: translation.errorMessage.form.auth.loginFail[language],
      });
    });
  };

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <S.Wrapper>
      <S.Title textAlign="center">
        {translation.auth.login.title.main[language]}
      </S.Title>
      <Controller
        name="email"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.login.form.title.email[language]}
            placeholder={
              translation.auth.login.form.placeholder.email[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.email?.message)}
            messageError={errors.email?.message}
            onKeyDown={handleEnter}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.login.form.title.password[language]}
            placeholder={
              translation.auth.login.form.placeholder.password[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.password?.message)}
            messageError={errors.password?.message}
            type="password"
            onKeyDown={handleEnter}
          />
        )}
      />
      <S.RecoverPassword>
        <LinkButton onClick={() => props.goToRecover()}>
          {translation.auth.login.hyperlink.forgotPassword[language]}
        </LinkButton>
      </S.RecoverPassword>

      <S.ButtonWrapper>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {translation.buttons.auth.enter[language]}
        </Button>
        <Button variant="text" onClick={() => navigate("/")}>
          {translation.buttons.auth.back[language]}
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default LoginPage;
