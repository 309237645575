const colors = {
  red: "#EF4444",
  redLight: "#F0D0D0",
  orange: "#F4994F",
  green: "#9CCC62",
  greenLight: "#E1F2CD",
  blue: "#da9121",
  grayLight: "#EBEBEB",
  grayDark: "#4B5768",
};

export default colors;
