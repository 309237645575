import { StyleSheet } from "@react-pdf/renderer";

export const pageStyle = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  space: {
    width: "100%",
    height: "0.5cm",
  },
});

export const headerStyle = StyleSheet.create({
  root: {
    width: "100%",
    backgroundColor: "#DADADA",
    padding: "1cm",
    paddingBottom: "1.5cm",
  },
  row: {
    display: "flex",
    flex: "1",
    flexDirection: "row",
  },
  rowLower: {
    marginTop: "20px",
    flex: "1",
    flexDirection: "row",
  },
  rowItem: { width: "50%", fontSize: "10px", paddingLeft: "20px" },
  textLower: {
    marginTop: "10px",
  },
});

export const footerStyle = StyleSheet.create({
  root: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    backgroundColor: "#DADADA",
    padding: "0.5cm",
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    fontSize: "10px",
  },
});

export const mapStyle = StyleSheet.create({
  wrapper: {
    display: "flex",
    width: "100%",
    paddingHorizontal: "1cm",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "10cm",
    height: "5cm",
  },
});
