import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { CriteriaAnalysisResponse, CriteriaData } from "types/data-access";
import { sendAnalysis as sendAnalysisService } from "http-services/data-access";
import { useAuthData } from "services/auth";
import * as S from "./styles";
import { alternativeStatusText, Criteria, useCriteria } from "./info";
import SectionCriteria from "./components/SectionCriteria";
import { Box } from "@mui/material";
import { Status } from "types/data-access";
import RequestButton from "./components/RequestButton";
import useConfig from "services/config/useConfig";

type Props = {
  criteriaPage: number;
  setAnalysisRequested: (page: number, s: boolean) => void;
  onStatusChange?: (v: Status) => void;
  criteriaAnalysisData: CriteriaAnalysisResponse[];
  currentCriteriaAnalysisData: CriteriaAnalysisResponse;
  upn: string;
};

const CriteriaResult = (props: Props) => {
  const { user } = useAuthData();
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const { status, criteria, analysisRequested } = props.currentCriteriaAnalysisData;
  const [newCriteria, setNewCriteria] = useState([...criteria]);
  const [shouldSave, setShouldSave] = useState(false);

  const { data } = useCriteria();
  const { translation, language } = useConfig();

  const current = data?.[props.criteriaPage] ?? [];
  let criterias: Criteria[];
  let producerCriterias: Criteria[] = [];

  // We create a subgroup of criterias in step 3
  if (props.criteriaPage === 2) {
    criterias = current.filter((c) => !c.producer);
    producerCriterias = current.filter((c) => c.producer);
  } else {
    criterias = current;
  }

  const previousStatus =
    props.criteriaPage > 0 && props.criteriaAnalysisData[props.criteriaPage - 1]?.status;

  const sendAnalysis = async () => {
    const { newStatus, newCriteria: updatedCriteria } = await sendAnalysisService(
      props.upn,
      props.criteriaPage + 1,
      newCriteria,
    );
    setIsValidating(false);
    setNewCriteria([...updatedCriteria]);

    if (props.onStatusChange && typeof props.onStatusChange == "function") {
      props.onStatusChange(newStatus);
    }

    setShouldSave(false);
  };

  useEffect(() => {
    if (shouldSave) {
      sendAnalysis();
    }
  }, [shouldSave]);

  useEffect(() => {
    if (criteria && criteria.length > 0) {
      setNewCriteria([...(criteria ?? [])]);
      setIsValidating(false);
    }
  }, [criteria]);

  const isAnalysisPage = !!props.criteriaPage ? props.criteriaPage >= 1 : false;

  const sectionCriteriaProps = {
    criteriaPage: props.criteriaPage,
    isValidating: isValidating,
    criteriaData: newCriteria,
    setCriteriaData: setNewCriteria,
  };

  return (
    <Box sx={{ width: "100%", maxWidth: "100%" }}>
      {props.criteriaPage === 2 && (
        <SectionCriteria
          sx={{ marginTop: "3rem" }}
          title={translation.packageCriteria.producerDocuments[language]}
          {...sectionCriteriaProps}
          criteriaList={producerCriterias}
          labels={alternativeStatusText}
          shouldSave={shouldSave}
        >
          {isAnalysisPage && (
            <S.SectionTitleGrid>
              <RequestButton
                profile={user?.profile}
                status={status as Status}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                previousStatus={previousStatus}
                upn={props.upn}
                criteriaPage={props.criteriaPage}
                setAnalysisRequested={props.setAnalysisRequested}
                analysisRequested={analysisRequested}
                handleCancel={() => setShouldSave(false)}
              />
            </S.SectionTitleGrid>
          )}
        </SectionCriteria>
      )}

      <SectionCriteria
        title={translation.packageCriteria.analysisCriteria[language]}
        criteriaList={criterias}
        {...sectionCriteriaProps}
        shouldSave={shouldSave}
      >
        {isAnalysisPage && (
          <S.SectionTitleGrid>
            <RequestButton
              profile={user?.profile}
              status={status as Status}
              isValidating={isValidating}
              setIsValidating={setIsValidating}
              previousStatus={previousStatus}
              upn={props.upn}
              criteriaPage={props.criteriaPage}
              setAnalysisRequested={props.setAnalysisRequested}
              analysisRequested={analysisRequested}
              handleCancel={() => setShouldSave(false)}
            />
          </S.SectionTitleGrid>
        )}
      </SectionCriteria>

      {isValidating && (
        <S.SectionTitleGrid>
          <Button variant="contained" onClick={() => setShouldSave(true)}>
            {translation.buttons.analysisCriteria.finish[language]}
          </Button>
        </S.SectionTitleGrid>
      )}
    </Box>
  );
};

export default CriteriaResult;
