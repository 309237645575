import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { Search } from "@mui/icons-material";
import MaskedInput from "react-text-mask";
import { useForm, Controller } from "react-hook-form";
import { PropsWithoutRef } from "react";
import { getRawUpn } from "utils/upn";
import * as S from "../../styles";

type SearchInputProps = PropsWithoutRef<{
  value?: string;
  disabled?: boolean;
  onChange?: (data: { query: string }) => void;
}>;

const mask = [
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  ".",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  "/",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];

const SearchInput = ({
  value = "",
  onChange = (data: { query: string }) => {},
  disabled = false,
}: SearchInputProps) => {
  const onSubmitHandler = (data: any) => {
    if (onChange) onChange(data);
  };

  const {
    handleSubmit,
    control,
    resetField,
    formState: { isValid, isDirty },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    resetField("query", {
      defaultValue: getRawUpn(value),
      keepDirty: false,
      keepError: false,
      keepTouched: false,
    });
  }, [resetField, value]);

  return (
    <S.Form onSubmit={handleSubmit(onSubmitHandler)}>
      <Controller
        name="query"
        rules={{
          validate: (value) => {
            return value?.length === 10;
          },
        }}
        control={control}
        render={({ field }) => (
          <MaskedInput
            {...field}
            onChange={(e) => {
              const unmasked = getRawUpn(e.target.value);
              return field.onChange(unmasked);
            }}
            defaultValue={value}
            placeholder="000.000/0000"
            guide={false}
            disabled={disabled}
            mask={mask}
            style={{
              transition: "all 300ms",
              width: "100%",
              margin: "0",
              padding: "0.5rem 1rem",
              minHeight: "3rem",
              fontSize: "1rem",
              fontWeight: "medium",
              color: "#4B5768",
              border: "1px solid #C3C7CD",
              borderRadius: "0.5rem",
              marginRight: "0.5rem",
            }}
          />
        )}
      ></Controller>

      <IconButton
        type="submit"
        size="large"
        disabled={disabled || !isValid || !isDirty}
      >
        <Search></Search>
      </IconButton>
    </S.Form>
  );
};

export default SearchInput;
