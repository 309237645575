import colors from "./colors";

const theme = {
  colors,
  typography: {
    fontFamily: [
      'Inter',
      'Arial',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
};

export default theme;
