import React, { useState } from "react";
import Pagination from "components/pagination/SimplePagination";
import * as S from "./styles";

type TableData = {
  [k: string]: any;
};

type Table = {
  [k: string]: any;
};

type HeaderKey = {
  label: string;
  key: string;
};

type Props = {
  headerKeys: HeaderKey[];
  data: TableData[];
  gridColumns: string;
};

const MAX_IN_PAGE = 9;

const TableFc = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(props.data.length / MAX_IN_PAGE);

  const displayedData = props.data.slice(
    currentPage * MAX_IN_PAGE,
    currentPage * MAX_IN_PAGE + MAX_IN_PAGE
  );

  const Header = props.headerKeys.map(({ label }) => <div>{label}</div>);
  const Data = displayedData.map((d, index) => (
    <S.TableContent key={index} gridColumns={props.gridColumns}>
      {props.headerKeys.map(({ key }) => (
        <div key={key}>{d[key]}</div>
      ))}
    </S.TableContent>
  ));

  return (
    <S.TableWrapper>
      <S.TableHeader gridColumns={props.gridColumns}>{Header}</S.TableHeader>
      {displayedData.length ? (
        Data
      ) : (
        <S.Message>Não existem informações para serem exibidas</S.Message>
      )}
      {totalPages > 0 && (
        <S.PaginationWrapper>
          <Pagination count={totalPages} setPage={setCurrentPage} />
        </S.PaginationWrapper>
      )}
    </S.TableWrapper>
  );
};

export default TableFc;
