import { client } from "http-services/client";
import { CriteriaAnalysisResponse, Plg, FilteredPlg, Status } from "types/data-access";
import { isoDatetimeToDateString } from "utils/date";
import { CriteriaData } from "types/data-access";
import { getRawUpn } from "utils/upn";

export const getPlg = async (upn: string) => {
  const response = await client.get<Plg>(`plg/${upn}`);

  const people = await getPeopleData(upn);

  const data = {
    ...response.data,
    people,
    titular_name:
      (people ?? []).find((p: any) => p.relation === "Titular\\Requerente" && !p.endDate)?.name ??
      response.data.titular_name,
  };

  return data;
};

export const getPlgBounds = async (upn: string): Promise<{ bbox: string; id: number }> => {
  return client.get(`polygons/${upn}/bounds`).then((res) => res.data ?? {});
};

export const getCriteriaAnalysis = async (
  upn: string,
  page: number,
): Promise<CriteriaAnalysisResponse> => {
  const response = await client.get(`/analysis/${getRawUpn(upn)}/step/${page + 1}`);

  return response.data;
};

type PeopleResponse = {
  tr_DSTipoRelacao: string;
  p_NMPessoa: string;
  p_NRCPFCNPJ: string;
  pp_DTInicioVigencia: string;
  pp_DTFimVigencia: string;
};

export type PeopleData = {
  relation: string;
  name: string;
  document: string;
  startDate: string;
  endDate: string;
};

export const filterPlg = async (params: { page: number, criteria?: string[] }) => {
  const response = await client.get<{ data: FilteredPlg[], count: number }>(`plg`, { params });

  return response.data;
};

export const getPeopleData = async (upn: string): Promise<PeopleData[]> => {
  try {
    const response = await client.get<PeopleResponse[]>(`people/plg/${getRawUpn(upn)}`);

    return response.data.map((i) => ({
      relation: i.tr_DSTipoRelacao,
      name: i.p_NMPessoa,
      document: i.p_NRCPFCNPJ,
      startDate: i.pp_DTInicioVigencia,
      endDate: i.pp_DTFimVigencia,
    }));
  } catch (error) {
    return [];
  }
};

export type Event = {
  id: number;
  event: string;
  date: string;
};

export const getEvents = async (upn: string): Promise<Event[]> => {
  try {
    const response = await client.get<Event[]>(`event/${getRawUpn(upn)}`);

    return response.data.map((event) => ({
      ...event,
      date: isoDatetimeToDateString(event.date),
    }));
  } catch (error) {
    return [];
  }
};

export const requestAnalysis = async (upn: string, step: number) => {
  const response = await client.post(`/analysis/${getRawUpn(upn)}/step/${step}/request-analysis`);

  return response.data;
};

type SendAnalysisResponse = {
  newStatus: Status;
  newCriteria: CriteriaData[];
};

export const sendAnalysis = async (
  upn: string,
  step: number,
  criteria?: CriteriaData[],
): Promise<SendAnalysisResponse> => {
  const response = await client.post(`/analysis/${getRawUpn(upn)}/step/${step}`, { criteria }, {});

  const { status: newStatus, criteria: newCriteria } = response.data;

  return { newStatus, newCriteria };
};

type ProcessData = {
  applicant: string;
  area: string;
  county: string;
  lastEvent: string;
  nup: string;
  phase: string;
  situation: boolean;
  substance: string;
};

export const downloadReport = async (criteria: string[]) => {
  try {
    // Make a GET request to the server to fetch the file
    const response = await client.post('/plg/report', { criteria }, {
      responseType: 'blob', // Specify the response type as blob
    });

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: 'application/octet-stream' });

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Extract the filename from the Content-Disposition header
    const contentDisposition = response.headers['content-disposition'];
    const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"$/);

    const filename = filenameMatch ? filenameMatch[1] : 'report.csv';

    // Set the download attribute and trigger a click event
    link.download = filename;
    link.click();

  } catch (error) {
    console.error('Error downloading file:', error);
  }
}

export const getProcessData = async (upn: string) => {
  const response = await client.get<ProcessData>(`plg/deep/${getRawUpn(upn)}`);

  return response.data;
};
