import _ from "lodash";
import { useQuery } from "@tanstack/react-query";
import { client } from "http-services/client";
import { Status } from "types/data-access";

export const statusText = {
  [Status.Ok]: "CONFORME",
  [Status.Pending]: "PENDENTE",
  [Status.Partial]: "REAVALIAÇÃO",
  [Status.InProgress]: "PENDENTE",
  [Status.NotOk]: "NÃO CONFORME",
  [Status.Blocked]: "PENDENTE",
};

export const alternativeStatusText = {
  [Status.Ok]: "RECEBIDO",
  [Status.Pending]: "PENDENTE",
  [Status.Partial]: "REAVALIAÇÃO",
  [Status.InProgress]: "NÂO RECEBIDO",
  [Status.NotOk]: "NÃO RECEBIDO",
  [Status.Blocked]: "NÂO RECEBIDO",
};

export interface Criteria {
  id: number;
  createdAt: string;
  updatedAt: string;
  label: string;
  key: string;
  step: number;
  description: string;
  producer: boolean;
}

export const getCriteria = async (): Promise<Criteria[]> => {
  const { data } = await client.get<Criteria[]>("/criteria");

  return data;
};

export const useCriteria = () => {
  const { data: d, error, status } = useQuery(["criteria"], getCriteria);

  const data = _.values(_.groupBy(d ?? [], (criteria) => criteria.step));

  return {
    error,
    status,
    data,
    raw: d ?? [],
  };
};
