import { Languages } from "types/config/i18n";

export const events = {
  title: {
    [Languages.PORTUGUESE]: "Eventos",
    [Languages.ENGLISH]: "Events",
  },
  code: {
    [Languages.PORTUGUESE]: "Código",
    [Languages.ENGLISH]: "Code",
  },
  name: {
    [Languages.PORTUGUESE]: "Nome",
    [Languages.ENGLISH]: "Name",
  },
  date: {
    [Languages.PORTUGUESE]: "Data",
    [Languages.ENGLISH]: "Date",
  },
  noInfoMessage: {
    [Languages.PORTUGUESE]: "Não existem informações para serem exibidas",
    [Languages.ENGLISH]: "There is no information to display",
  },
};

export const relationship = {
  title: {
    [Languages.PORTUGUESE]: "Pessoas relacionadas",
    [Languages.ENGLISH]: "Related people",
  },
  relationship: {
    [Languages.PORTUGUESE]: "Tipo de relação",
    [Languages.ENGLISH]: "Type of relationship",
  },
  name: {
    [Languages.PORTUGUESE]: "Nome",
    [Languages.ENGLISH]: "Name",
  },
  cpfCnpj: {
    [Languages.PORTUGUESE]: "CPF/CNPJ",
    [Languages.ENGLISH]: "CPF/CNPJ",
  },
  startDate: {
    [Languages.PORTUGUESE]: "Data inicial",
    [Languages.ENGLISH]: "Start date",
  },
  endDate: {
    [Languages.PORTUGUESE]: "Data Final",
    [Languages.ENGLISH]: "End date",
  },
};
