import React, { useCallback } from "react";
import { Select, MenuItem, Box, Button, Icon, Checkbox, FormControlLabel, Accordion, AccordionSummary, AccordionDetails, Badge, IconButton } from "@mui/material";
import { PropsWithoutRef } from "react";
import useConfig from "services/config/useConfig";
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { useCriteria } from "../../ModalDataAccess/CriteriaAnalysis/CriteriaResult/info";
import { package1, package2, package3 } from "../../../../../../../services/config/i18n/package";
import _, { set } from "lodash";


type FilterInputProps = PropsWithoutRef<{
  value?: string | boolean;
  onChange?: (data: { filterBy?: string, criteria?: string[] }) => void;
}>;

const FilterInput = ({ onChange }: FilterInputProps) => {
  const { translation, language } = useConfig();

  const [filterBy, setFilterBy] = React.useState<string[]>([]);

  const { data, status, error } = useCriteria();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCheckboxChange = (key: string) => (e: any) => {
    if (e.target.checked) {
      setFilterBy(_.uniq([...filterBy, key]));
    } else {
      setFilterBy(filterBy.filter((item) => item !== key));
    }
  };

  const handleCheckboxGroupChange = (criterias: string[]) => (e: any) => {
    if (e.target.checked) {
      setFilterBy(_.uniq([...filterBy, ...criterias.map((item: any) => item.key)]));
    } else {
      setFilterBy(filterBy.filter((item) => !criterias.map((item: any) => item.key).includes(item)));
    }
  }

  const onSubmitHandler = (value: string) => {
    onChange?.({ filterBy: value, criteria: [...filterBy] });
  }


  const applyFilterHandler = () => {
    if (onChange) onChange({ criteria: [...filterBy] });
    handleClose();
  }

  const handleReset = () => {
    setFilterBy([]);
    if (onChange) onChange({ criteria: [] });
    handleClose();
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "row",
    }}>
      <Button variant={filterBy.length ? "contained" : "outlined"} onClick={handleOpen}>
        <Icon>{filterBy.length ? "filter_alt" : "filter_alt_off"}</Icon>
        {filterBy.length && <Badge sx={{ marginLeft: 0.5 }}>{filterBy.length}</Badge>}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: "75%",
          bgcolor: 'background.paper',
          borderRadius: "0.5rem",
          boxShadow: 24,
          px: 4,
          pt: 2,
          pb: 4,
        }}>
          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "end", marginBottom: 2 }}>
            <IconButton sx={{ marginLeft: "auto", color: "primary" }} aria-label="close" onClick={handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ display: "flex" }}>
            <Box>
              {translation.map.search.filter.modal.title[language]}
              <Badge badgeContent={filterBy.length} color="primary" sx={{ marginLeft: "1.5rem" }} />
            </Box>
            <Box sx={{ marginLeft: "auto", display: "flex", gap: 2 }}>
              <Button variant="text" onClick={handleReset} color="error">
                {translation.map.search.filter.modal.close[language]}
              </Button>
              <Button variant="contained" onClick={applyFilterHandler}>
                {translation.map.search.filter.modal.action[language]}
              </Button>
            </Box>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {translation.map.search.filter.modal.description[language]}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: "1rem" }}>
            {data?.map((criterias: any, index: number) => {
              const pkg = index + 1;

              if (index > 0) {
                return <></>
              }

              const t = (key: string) => {
                const options = _.merge({}, package1, package2, package3);

                return options?.criteria?.[key]?.title?.[language];
              }

              const children = criterias?.map((item: any) => (
                <FormControlLabel key={item.key} sx={{ display: 'flex', marginLeft: "1rem", flexDirection: 'row', alignItems: 'center' }} control={
                  <Checkbox
                    value={item.key}
                    checked={filterBy.includes(item.key)}
                    onChange={handleCheckboxChange(item.key)}
                  />
                } label={t(item.key)} />
              ))

              const allChecked = criterias.map((item: any) => item.key).every((item: any) => filterBy.includes(item));
              const someChecked = criterias.map((item: any) => item.key).some((item: any) => filterBy.includes(item));

              return <Accordion variant="outlined" sx={{ marginBottom: "2", maxHeight: "300px", overflowY: "scroll", position: "relative" }}>
                <AccordionSummary
                  sx={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "white", borderBottom: "1px solid #E0E0E0" }}
                  expandIcon={<Icon>expand_more</Icon>}
                >
                  <Box onClick={e => e.stopPropagation()} sx={{ display: "flex", alignItems: "center", gap: "2" }}>
                    <Checkbox
                      checked={allChecked}
                      indeterminate={allChecked !== someChecked}
                      onChange={
                        (e) => {
                          e.stopPropagation();
                          handleCheckboxGroupChange(criterias)(e);
                        }
                      }
                    />
                    <Typography>{translation.map.search.filter.pkg[language]} {pkg}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  {children}
                </AccordionDetails>
              </Accordion>
            })}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default FilterInput;
