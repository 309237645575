import React, { PropsWithoutRef, useEffect, useState } from "react";
import Table from "components/data-display/Table";
import { Event, getEvents } from "http-services/data-access";
import { Wrapper, StyledTitle } from "../styles";
import useConfig from "services/config/useConfig";

type Props = PropsWithoutRef<{
  upn?: string;
}>;

const Events = ({ upn }: Props) => {
  const { translation, language } = useConfig();

  const [data, setData] = useState<Event[]>([]);

  useEffect(() => {
    if (!upn) return;

    getEvents(upn).then(setData);
  }, [upn]);

  return (
    <Wrapper>
      <StyledTitle>{translation.modal.events.title[language]}</StyledTitle>
      <Table
        headerKeys={[
          { key: "id", label: translation.modal.events.code[language] },
          { key: "event", label: translation.modal.events.name[language] },
          { key: "date", label: translation.modal.events.date[language] },
        ]}
        data={data}
        gridColumns="10% 1fr 10%"
      />
    </Wrapper>
  );
};

export default Events;
