import React, { PropsWithChildren } from "react";
import * as T from "components/layout/titles";
import CriteriaItem from "./CriteriaItem";
import { CriteriaData } from "types/data-access";
import { Criteria } from "../info";
import { Box, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import * as S from "./styles";

type Props = {
  title: string;
  criteriaList: Criteria[];
  criteriaPage: number;
  isValidating: boolean;
  criteriaData: CriteriaData[];
  labels?: {
    [s: string]: string;
  };
  setCriteriaData: (c: CriteriaData[]) => void;
  shouldSave: boolean;
  sx?: SxProps<Theme>;
};

const SectionCriteria = (props: Props & PropsWithChildren) => {
  return (
    <Box sx={props.sx}>
      <T.SectionTitle>{props.title}</T.SectionTitle>
      {props.children}
      <S.CriteriaWrapper>
        {props.criteriaList?.map(({ label, description, key }) => (
          <CriteriaItem
            key={label}
            labels={props.labels}
            item={label}
            description={description ?? ""}
            isValidating={props.isValidating}
            criteriaPage={props.criteriaPage}
            criteriaData={props.criteriaData}
            newCriteria={props.criteriaData}
            setNewCriteria={props.setCriteriaData}
            criteriaKey={key}
            shouldSave={props.shouldSave}
          />
        ))}
      </S.CriteriaWrapper>
    </Box>
  );
};

export default SectionCriteria;
