import styled from "styled-components";
import { Box, Stack } from "@mui/material";

export const SearchWrapper = styled(Stack)`
  background-color: white;
  border-radius: 8px;
  max-height: 80vh;
  box-sizing: border-box;
`;

export const TabWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 4rem;
`;

export const Form = styled.form`
  display: flex;
  flex-grow: 1;
`;

type TabPanelProps = {
  index: number;
  currentTab: number;
};

export const TabPanel = styled(Box)<TabPanelProps>`
  display: ${(props) => (props.index === props.currentTab ? "flex" : "none")};
  flex-direction: column;
  padding: 1rem;
  max-height: 90%;
  overflow: auto;
`;
