import styled from "styled-components";
import Button from "@mui/material/Button";

const DefaultButton = styled(Button)`
  &.MuiButtonBase-root {
    font-size: 1rem;
    font-weight: 700;
    text-transform: none;
    padding: 10px;
    background: none;
    border-color: ${({ theme }) => theme.colors.blue};
    border-width: 2px;
    border-style: solid;
    color: ${({ theme }) => theme.colors.blue};
    border-radius: 99px;
    box-shadow: none;

    &:hover {
      background: ${({ theme }) => theme.colors.blue};
      color: #fff;
    }
  }
`;

export default DefaultButton;
