import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem("nap_access_token");

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.removeItem("nap_access_token");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
