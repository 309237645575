export const items = [
  {
    label: "NUP (Número Único do Processo)",
    key: "nup",
  },
  {
    label: "Situação",
    key: "situation",
    format: (value: boolean) => {
      return value ? "Ativo" : "Inativo";
    },
  },
  {
    label: "Requerente / Titular",
    key: "applicant",
  },
  {
    label: "Área",
    key: "area",
  },
  {
    label: "Último evento",
    key: "lastEvent",
  },
  {
    label: "Substâncias",
    key: "substance",
  },
  {
    label: "Município",
    key: "county",
  },
  {
    label: "Fase",
    key: "phase",
  },
];
