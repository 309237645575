import React from "react";
import * as S from "./styles";
import { chooseDisplayer } from "../status-icon";
import { CriteriaAnalysisResponse, Status } from "types/data-access";
import { Typography } from "@mui/material";
import useConfig from "services/config/useConfig";

const statusText = {
  [Status.Ok]: "Apto",
  [Status.Pending]: "Pendente de análise",
  [Status.Partial]: "Reavaliação",
  [Status.InProgress]: "Em análise",
  [Status.NotOk]: "Inapto",
  [Status.Blocked]: "Não liberado",
};

type Props = {
  criteriaData: CriteriaAnalysisResponse[];
  setPage: (v: number) => void;
};

const StepsDisplayer = (props: Props) => {
  const { language, translation } = useConfig();

  const translateStatus = (status: string) => {
    const statusTranslation = Object.values(
      translation.packageCriteria.compliance.status
    ).find(
      (s) => s?.["pt-br"].toLocaleLowerCase() === status?.toLocaleLowerCase()
    );

    return statusTranslation ? statusTranslation[language] : status;
  };

  const StatusData = props.criteriaData.map(
    ({ status, step, compliancePotential }, index) => (
      <S.IconWrapper onClick={() => props.setPage(index)}>
        {chooseDisplayer(1, 0, status)}
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {`${translation.system.package[language]} ${index + 1}`}
        </Typography>
        <S.StatusWrapper
          status={status}
          variant="subtitle2"
          sx={{ fontWeight: "bold" }}
        >
          {step === 3 &&
            ![Status.Pending, Status.Blocked, Status.InProgress].includes(status)
            ? `${compliancePotential}%`
            : translateStatus(statusText[status])}
        </S.StatusWrapper>
      </S.IconWrapper>
    )
  );

  const NextStepMessage = (
    <Typography variant="caption">
      {translation.system.nextStepPackageMessage[language]}
    </Typography>
  );

  return (
    <S.Wrapper>
      {StatusData[0]}
      <S.NextStepLine>
        {props.criteriaData[0]?.status === Status.Ok && NextStepMessage}
      </S.NextStepLine>
      {StatusData[1]}
    </S.Wrapper>
  );
};

export default StepsDisplayer;
