import styled from "styled-components";

type Props = {
  gridColumns: string;
};

export const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

export const TableHeader = styled.div<Props>`
  display: grid;
  padding: 10px 0;
  column-gap: max(40px, 3%);
  grid-template-columns: ${(props) => props.gridColumns};
  font-weight: bold;
  font-size: 0.8rem;
  color: ${(props) => props.theme.colors.blue};
`;

export const TableContent = styled(TableHeader)`
  font-size: 0.9rem;
  color: ${(props) => props.theme.colors.grayDark};
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: calc(100% - 80px);
  left: 10px;
  flex: 1;
  flex-grow: 1;
  width: calc(100% - 20px);
`;

export const Message = styled.span`
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: 20px;
  font-size: 14px;
  font-weight: bold;
`;
