import styled from "styled-components";

export const ResultWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
`;


export const DisplayerWrapper = styled.div`
  display: flex;
`

type Props = {
  hasGauge: boolean;
};


export const ResultIcon = styled.div<Props>`
  display: flex;
  min-width: 120px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  span {
    font-weight: 800;
    margin-top: ${(props) => (props.hasGauge ? "-30px" : "10px")};
    text-align: center;
  }

  svg {
    width: max(3.8vw, 65px);
    height: max(3.8vw, 65px);
  }
`;

export const ResultText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2%;
  overflow-x: auto;

  span {
    &:nth-child(2) {
      color: ${(props) => props.theme.colors.grayDark};
      font-size: 16px;
      margin-top: 15px;
    }
  }
`;

export const CriteriaWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GaugeWrapper = styled.div`
margin-bottom: 20px;
`;
