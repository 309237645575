import { Typography } from "@mui/material";
import styled from "styled-components";
import { Status } from "types/data-access";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  column-gap: 30px;
`;

export const IconWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    cursor: pointer;
  }

  &:active {
    filter: brightness(70%);
  }

  svg {
    width: 35%;
    height: 35%;
  }

  span,
  h6 {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;

const StatusColor = {
  [Status.Ok]: "#79AF65",
  [Status.Partial]: "#F4994F",
  [Status.Pending]: "#A4A4AD",
  [Status.Blocked]: "#A4A4AD",
  [Status.InProgress]: "#A4A4AD",
  [Status.NotOk]: "#FF8080",
};

type StatusWrapperProps = {
  status: Status;
};

export const StatusWrapper = styled(Typography) <StatusWrapperProps>`
  color: ${(props) => StatusColor[props.status] || "yellow"};
  text-align: center;
`;

export const NextStepLine = styled.div`
  width: 100%;
  height: 50%;
  border-bottom: 2px solid #d9d9d9;
  box-sizing: border-box;
`;
