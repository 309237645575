import React from "react";
import { Status } from "types/data-access";
import Gauge from "components/displayer/Gauge";
import { ReactComponent as ComplianceApprove } from "assets/image/icons/badges/compliance-approve.svg";
import { ReactComponent as ComplianceReject } from "assets/image/icons/badges/compliance-reject.svg";
import { ReactComponent as ComplianceWarn } from "assets/image/icons/badges/compliance-warn.svg";
import * as S from "./styles";

export const statusText = {
  [Status.Ok]: "Apto",
  [Status.Pending]: "Pendente de análise",
  [Status.InProgress]: "Em análise",
  [Status.NotOk]: "Inapto",
  [Status.Partial]: "Reavaliação",
  [Status.Blocked]: "Análise não liberada",
};

export const statusIcon = {
  [Status.Ok]: <ComplianceApprove />,
  [Status.Pending]: <ComplianceWarn />,
  [Status.Partial]: <ComplianceWarn />,
  [Status.InProgress]: <ComplianceWarn />,
  [Status.NotOk]: <ComplianceReject />,
  [Status.Blocked]: <ComplianceWarn />,
};

export const chooseDisplayer = (
  criteriaPage: number,
  compliancePotential?: number,
  status?: Status
) => {
  if (criteriaPage === 2) {
    return (
      <S.GaugeWrapper>
        <Gauge percentage={compliancePotential || 0} />
      </S.GaugeWrapper>
    );
  }
  if (status) {
    return statusIcon[status];
  }
};

export const chooseText = (
  criteriaPage: number,
  percentage: number,
  status?: Status
) => {
  if (
    criteriaPage === 2 &&
    status !== Status.Pending &&
    status !== Status.Blocked
  ) {
    if (percentage <= 26.67) return "Baixo";
    if (percentage <= 66.67) return "Médio";
    return "Alto";
  }
  if (status) {
    return statusText[status];
  }
};
