import styled from "styled-components";
import FormHelperText from "@mui/material/FormHelperText";
import OutlinedInput from "@mui/material/OutlinedInput";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const InputText = styled(FormHelperText)`
  margin: 0 !important;
`;

export const Input = styled(OutlinedInput)`
  .MuiOutlinedInput-input {
    ::placeholder {
      color: #1f2937;
      font-weight: 500;
      font-size: 14px;
      opacity: 1;
    }
  }
`;

export const ErrorIcon = styled(ReportProblemIcon)`
  color: ${(props) => props.theme.colors.red};
`;
