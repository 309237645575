import React from "react";
import { LangNames, Languages } from "types/config/i18n";
import useConfig from "services/config/useConfig";
import Select from "@mui/material/Select";
import { FormControl, MenuItem } from "@mui/material";

const LanguageSelect = () => {
  const { language, setLanguage } = useConfig();

  const options = Object.values(Languages).map((key) => ({
    value: key,
    label: LangNames[key][language],
  }));

  return <div>
    <FormControl sx={{ m: 1, minWidth: 80, p: 0 }}>
      <Select
        id="lang"
        value={language}
        onChange={(e) => setLanguage(e.target.value as Languages)}
        autoWidth
        variant="outlined"
        MenuProps={{
          sx: {
            '& .MuiPaper-root': {
              backgroundColor: '#fccb5b',
              color: 'black'
            }
          }
        }}
        sx={{
          height: '2.5rem',
          color: 'white',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'white !important',
          },
          '& .MuiSvgIcon-root': {
            color: 'white'
          },
        }}
      >
        {options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)}
      </Select>
    </FormControl>
  </div>
};

export default LanguageSelect;
