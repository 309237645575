import React, { useCallback } from "react";
import { Box, Button, CircularProgress, Icon, Typography } from "@mui/material";
import { getRawUpn } from "utils/upn";
import FilterInput from "./FilterInput";
import FilterResult from "./FilterResult";
import * as S from "../styles";
import useConfig from "services/config/useConfig";
import { downloadReport } from "../../../../../../http-services/data-access";

type Props = {
  index: number;
  currentTab: number;
  setIsLoading: (v: boolean) => void;
  onSearch?: (upn: string) => Promise<void>;
  onFilterChange?: (filterBy: string) => void;
  setFilter: (v: string) => void;
  filter: string;
  setModalIsOpen: (v: boolean) => void;
};

const FilterPanel = (props: Props) => {
  const { translation, language } = useConfig();

  const [criteria, setCriteria] = React.useState<string[]>([]);
  const [data, setData] = React.useState<string[]>([]);
  const [download, setDownload] = React.useState<boolean>(false);

  const onSelect = async (upn: string) => {
    if (props.onSearch) {
      props.setIsLoading(true);
      try {
        await props.onSearch(getRawUpn(upn));
      } finally {
        props.setIsLoading(false);
      }
    }
  };

  // Update filter if form changes
  const onFilterChange = useCallback((data: { filterBy?: string, criteria?: string[] }) => {
    const { filterBy, criteria } = data;
    setData(criteria ?? []);

    props.setFilter(filterBy ?? "");
    props.onFilterChange?.(filterBy ?? "");

    setCriteria(criteria ?? []);
  }, [props]);

  const t = translation.packageCriteria.criterias;

  const startDownload = async () => {
    if (download) return;

    setDownload(true);

    try {
      await downloadReport(data);
    } catch (err) { } finally {
      setDownload(false);
    }
  };


  return (
    <S.TabPanel index={props.index} currentTab={props.currentTab}>
      <Box>
        <Typography sx={{ mb: 1, fontWeight: "bold" }}>
          {translation.map.search.filter.title[language]}:
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <FilterInput onChange={(data) => onFilterChange(data)}></FilterInput>
          <Button onClick={startDownload} disabled={data.length === 0} variant="contained">{download ? <><CircularProgress size={16} sx={{ mr: 2, color: 'white' }} />{translation.system.downloadProgress[language]}</> : translation.system.downloadReport[language]}</Button>
        </Box>
        {data.length > 0 && <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{translation.system.reportTitle[language]}</Typography>}
        {data.length > 0 && <Typography sx={{ mb: 2, fontSize: 14, color: 'lightgray' }}>{translation.system.reportWarning[language]}</Typography>}
        <Box sx={{}}>{data.map((item, index) => (
          <Typography key={index} sx={{ mb: 1, mt: 1, color: 'gray', fontSize: 14 }}>
            ✅ {t?.[item]?.title?.[language] ?? item}
          </Typography>
        ))}
        </Box>
      </Box>

      <FilterResult
        onSelect={(data) => onSelect(data)}
        criteria={criteria}
        filter={props.filter}
        setModalIsOpen={props.setModalIsOpen}
      />
    </S.TabPanel>
  );
};

export default FilterPanel;
