import React, { useState, MutableRefObject, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CriteriaAnalysisResponse, Plg } from "types/data-access";
import { Status } from "types/data-access";
import * as S from "./styles";
import Displayer from "./Displayer";
import Text from "./Text";
import PdfLayout from "./PdfLayout";
import { useScreenshot } from "use-react-screenshot";
import useConfig from "services/config/useConfig";

type Props = {
  criteriaPage: number;
  currentCriteriaAnalysisData: CriteriaAnalysisResponse;
  screenshooter?: SimpleMapScreenshoter;
  screenRef: MutableRefObject<any>;
  plg: Plg | undefined;
};

const ComplianceResult = (props: Props) => {
  const { translation, language } = useConfig();

  const [ready, setReady] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [mapImage, setMapImage] = useState("");
  const [displayerImage, takeScreenshot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });
  const [pageScreenshot, setPageScreenshot] = useState<number | null>(null);
  const [displayerTitle, setDisplayerTitle] = useState("");
  const [displayerDescription, setDisplayerDescrition] = useState("");
  const [displayerImageText, setDisplayerImageText] = useState("");

  const displayerRef = useRef<HTMLDivElement>(null);

  const resetImages = () => {
    setMapImage("");
    takeScreenshot({});
  };

  useEffect(() => {
    if (mapImage && displayerImage) {
      if (pageScreenshot !== props.criteriaPage) {
        setReady(false);
        resetImages();
      } else {
        setReady(true);
      }
    }
  }, [mapImage, displayerImage]);

  useEffect(() => {
    setReady(false);
  }, [props.criteriaPage]);

  const takeMapScreenShot = async () => {
    if (!props.screenshooter) return;

    setGenerating(true);
    const image = await props.screenshooter.takeScreen("blob");
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = reader.result;
      setMapImage(base64 as string);
      setGenerating(false);
    };
    reader.readAsDataURL(image as any);
  };

  const getImages = async () => {
    setPageScreenshot(props.criteriaPage);
    await takeMapScreenShot();
    takeScreenshot(displayerRef.current);
  };

  return (
    <S.ResultWrapper>
      <S.DisplayerWrapper>
        <Displayer
          criteriaPage={props.criteriaPage}
          status={props.currentCriteriaAnalysisData.status as Status}
          compliancePotential={
            props.currentCriteriaAnalysisData.compliancePotential
          }
          displayerRef={displayerRef}
          setText={setDisplayerImageText}
        />
        {/* <Text
          criteriaPage={props.criteriaPage}
          setTitle={setDisplayerTitle}
          setDescription={setDisplayerDescrition}
        /> */}
      </S.DisplayerWrapper>

      {ready ? (
        <PDFDownloadLink
          document={
            <PdfLayout
              mapImage={mapImage}
              package={props.criteriaPage}
              displayerImage={displayerImage}
              currentCriteriaAnalysisData={props.currentCriteriaAnalysisData}
              plg={props.plg}
              displayerTitle={displayerTitle}
              displayerDescription={displayerDescription}
              displayerImageText={displayerImageText}
              language={language}
            />
          }
          fileName={`pacote_${props.criteriaPage + 1}.pdf`}
        >
          <Button
            variant="outlined"
            sx={{ width: "100%", marginRight: "auto", height: "auto" }}
            endIcon={<SaveAltIcon />}
            onClick={takeMapScreenShot}
          >
            {translation.system.pdf.download[language]}
          </Button>
        </PDFDownloadLink>
      ) : (
        <Button
          variant="outlined"
          sx={{ marginRight: "auto", height: "auto" }}
          endIcon={<SaveAltIcon />}
          onClick={getImages}
        >
          {generating
            ? translation.system.pdf.generating[language]
            : translation.system.pdf.generate[language]}
        </Button>
      )}
    </S.ResultWrapper>
  );
};

export default ComplianceResult;
