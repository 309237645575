import React, { FC } from "react";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SouthWestIcon from "@mui/icons-material/SouthWest";
import * as S from "./styles";
import CriteriaAnalysis from "./CriteriaAnalysis";
import ProcessData from "./ProcessData";
import Events from "./Events";
import RelatedMember from "./RelatedMember";
import { IconButton } from "@mui/material";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { getRawUpn } from "utils/upn";
import { Plg } from "types/data-access";
import useConfig from "services/config/useConfig";

type Props = {
  open: boolean;
  onClose: () => void;
  plg?: Plg;
  screenshooter?: SimpleMapScreenshoter;
};

type TabDataProps = React.PropsWithChildren<{
  open: boolean;
}>;

const TabData: FC<TabDataProps> = ({ open, children }: TabDataProps) => {
  return (
    <div
      style={{
        display: open ? "flex" : "none",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {children}
    </div>
  );
};

const DataAccess = ({ open, onClose, plg, screenshooter }: Props) => {
  const { translation, language } = useConfig();

  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const upn = getRawUpn(plg?.p_upn);

  const handleChange = (event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  const getTabProps = (index: number) => ({
    key: index,
    id: `analysis-criteria-tab-${index}`,
    "aria-controls": `analysis-criteria-tab-${index}`,
    sx: {
      textTransform: "none",
    },
  });

  return (
    <Modal keepMounted open={open} onClose={onClose}>
      <S.ModalWrapper>
        <S.Header>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="criteria-analysis-tabs"
            textColor="inherit"
            allowScrollButtonsMobile
            scrollButtons
            variant="scrollable"
            sx={{ flexGrow: 1 }}
          >
            <Tab
              label={
                translation.map.search.modal.tabs.analysisCriteria[language]
              }
              {...getTabProps(0)}
            />
            <Tab
              label={translation.map.search.modal.tabs.dataCase[language]}
              {...getTabProps(1)}
            />
            <Tab
              label={translation.map.search.modal.tabs.events[language]}
              {...getTabProps(2)}
            />
            <Tab
              label={translation.map.search.modal.tabs.relatedPeople[language]}
              {...getTabProps(3)}
            />
          </Tabs>
          <IconButton onClick={onClose} sx={{ ml: 4, mr: 2, color: "white" }}>
            <SouthWestIcon />
          </IconButton>
        </S.Header>

        <S.SubHeader>
          <span>{plg?.p_upn}</span>
          <span>{plg?.titular_name}</span>
        </S.SubHeader>

        <S.Content>
          <TabData open={open && tabIndex === 0}>
            <CriteriaAnalysis
              upn={upn}
              screenshooter={screenshooter}
              plg={plg}
            />
          </TabData>
          <TabData open={open && tabIndex === 1}>
            <ProcessData upn={upn} />
          </TabData>
          <TabData open={open && tabIndex === 2}>
            <Events upn={upn} />
          </TabData>
          <TabData open={open && tabIndex === 3}>
            <RelatedMember upn={upn} />
          </TabData>
        </S.Content>
      </S.ModalWrapper>
    </Modal>
  );
};

export default DataAccess;
