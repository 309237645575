import React, { useRef } from "react";
import { Box } from "@mui/material";

// Components
import Header from "components/header";
import { NAVBAR_HEIGHT } from "components/header/components/BaseHeader";

// Sections
import Hero from "./Hero";
import MapSection from "./MapSection";
import AboutSection from "./AboutSection";
import FAQ from "./FAQ";
import Footer from "./Footer";

import backgroundImage from "../../assets/image/pages/landing-page/bg.jpg"

const LandingPage = () => {
  const faqRef = useRef();
  const aboutRef = useRef();
  const footerRef = useRef();

  return (
    <Box>
      <Header faqRef={faqRef} aboutRef={aboutRef} footerRef={footerRef} />
      <Box ref={aboutRef} sx={{ marginTop: NAVBAR_HEIGHT, padding: 2, width: "100%", position: "relative" }}>
        <Box sx={{ position: "relative", zIndex: 2, maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", display: "flex", width: "100%", flexDirection: "column", p: 2 }}>
          <Hero />
        </Box>
        <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundImage: `url(${backgroundImage})`, backgroundSize: "auto 100%", backgroundRepeat: "repeat", opacity: 0.2 }}>
        </Box>
      </Box>
      <Box ref={faqRef} sx={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", display: "flex", width: "100%", flexDirection: "column", p: 2, py: 12 }}>
        <AboutSection />
      </Box>
      <Box sx={{ background: "#EAE2BE", width: "100%", padding: 2 }}>
        <Box sx={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", display: "flex", width: "100%", flexDirection: "column", p: 2, py: 12 }}>
          <FAQ faqRef={faqRef} />
        </Box>
      </Box>
      <Box sx={{ maxWidth: "1300px", marginLeft: "auto", marginRight: "auto", display: "flex", width: "100%", flexDirection: "column", p: 2, py: 12 }}>
        <MapSection />
      </Box>

      <Footer footerRef={footerRef} />
    </Box>
  );
};

export default LandingPage;
