import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import map from "assets/image/pages/landing-page/map.png";
import useConfig from "services/config/useConfig";

const MapSection = () => {
  const { translation, language } = useConfig();

  return (
    <Stack
      direction="column"
      sx={{
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        my: "3rem"
      }}
    >
      <Typography sx={{ width: "100%", maxWidth: "1300px", fontSize: "1.5rem", textTransform: "uppercase", fontWeight: "bold", mb: 2 }}>
        {translation.home.section.map.title[language]}
      </Typography>
      <img
        alt="Mapa"
        src={map}
        style={{
          width: "100%",
          maxWidth: "1300px",
          height: "100%",
        }}
      />
    </Stack>
  );
};

export default MapSection;
