export enum Profile {
  TECHNICIAN = "technician",
  BUYER = "buyer",
  PRODUCER = "producer",
  ADMIN = 'admin',
}

export const ProfileLabel = {
  [Profile.BUYER]: "Comprador",
  [Profile.PRODUCER]: "Produtor",
  [Profile.TECHNICIAN]: "Técnico",
  [Profile.ADMIN]: "Admin",
};
