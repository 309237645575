import { Languages } from "types/config/i18n";

export const header = {
  items: {
    about: {
      [Languages.PORTUGUESE]: "Sobre",
      [Languages.ENGLISH]: "About",
    },
    how: {
      [Languages.PORTUGUESE]: "Como funciona",
      [Languages.ENGLISH]: "Features",
    },
    employees: {
      [Languages.PORTUGUESE]: "Colaboradores",
      [Languages.ENGLISH]: "Team",
    },
    signup: {
      [Languages.PORTUGUESE]: "Cadastre-se gratuítamente",
      [Languages.ENGLISH]: "Login",
    },
    soon: {
      [Languages.PORTUGUESE]: "Aguarde lançamento",
      [Languages.ENGLISH]: "Coming Soon",
    },
  },
};
