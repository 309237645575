import React from "react";
import { Controller, useForm } from "react-hook-form";
import Textfield from "components/inputs/Textfield";
import Select from "components/inputs/Select";
import Button from "components/button/DefaultButton";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { Profile } from "types/auth";
import { useAuth } from "services/auth";
import { RegisterDto } from "http-services/auth";
import useConfig from "services/config/useConfig";
import { client } from "../../http-services/client";
import { Box, Snackbar } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";

export const register = async (data: RegisterDto): Promise<void> => {
  await client.post(
    `${process.env.REACT_APP_BACKEND_URL}/auth/admin/users`,
    data
  );
};

const RegisterPage = () => {
  const navigate = useNavigate();
  const { language, translation } = useConfig();

  const [open, setOpen] = React.useState(false);

  const client = useQueryClient();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: Omit<RegisterDto, "profile">) => {
    try {
      await register(data as RegisterDto);
      setOpen(true);
      
      reset();

      client.refetchQueries(["admin", "users"]);
    } catch (error: any) {
      const message = error?.response?.data?.message ?? "";
      if (message === "The request conflicts with an existing entity.")
        setError("email", {
          message:
            translation.errorMessage.form.emailAlreadyRegistered[language],
          type: "custom",
        });
      }
  };

  return (
    <form style={{ display: "flex", flexDirection: "column"}}>
      <S.Title textAlign="left">
        {translation.auth.register.title.main[language]}
      </S.Title>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        message="Usuário criado com sucesso!"
      />
      <Controller
        name="name"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.name[language]}
            placeholder={
              translation.auth.register.form.placeholder.name[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.name?.message)}
            messageError={errors.name?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
          pattern: {
            value:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi,
            message: translation.errorMessage.form.invalidEmail[language],
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.email[language]}
            placeholder={
              translation.auth.register.form.placeholder.email[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.email?.message)}
            messageError={errors.email?.message}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
          minLength: {
            value: 8,
            message: "A senha deve conter no mínimo 8 caracteres",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.password[language]}
            placeholder={
              translation.auth.register.form.placeholder.password[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.password?.message)}
            messageError={errors.password?.message}
            type="password"
          />
        )}
      />
      <S.ButtonWrapper>
        <Button variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
          Cadastrar
        </Button>
      </S.ButtonWrapper>
    </form>
  );
};

export default RegisterPage;
