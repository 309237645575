import styled from "styled-components";
import Button from "@mui/material/Button";
import * as T from "components/layout/titles";
import { Select } from "@mui/material";
import { Status } from "types/data-access";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Criteria = styled.div`
  display: grid;
  grid-template-columns: 2fr 0fr 2fr;
  column-gap: 1vmin;
  align-items: center;
  justify-content: center;
  width: 100%;

  & > svg:hover {
    cursor: pointer;
  }
`;

type Props = {
  status: Status;
};

export const colorCheck = {
  [Status.Ok]: "#79AF65",
  [Status.Pending]: "#A4A4AD",
  [Status.Partial]: "#F4994F",
  [Status.Blocked]: "#A4A4AD",
  [Status.InProgress]: "#A4A4AD",
  [Status.NotOk]: "#FF8080",
};

export const StyledButton = styled(Button) <Props>`
  background-color: ${(props) => colorCheck[props.status]};

  &:disabled {
    background-color: ${(props) => colorCheck[props.status]} !important;
    color: white !important;
  }
  &:hover {
    background-color: ${(props) => colorCheck[props.status]};
    filter: brightness(80%);
  }
`;

export const StyledSelect = styled(Select) <Props>`
  background-color: ${(props) => colorCheck[props.status]};
  padding: 5px 0px !important;
  width: 150px;
  z-index: 9999;

  &:disabled {
    background-color: ${(props) => colorCheck[props.status]} !important;
    color: white !important;
  }
  &:hover {
    background-color: ${(props) => colorCheck[props.status]};
    filter: brightness(80%);
  }

  option {
    z-index: 9999;
  }
`;

export const SectionTitleGrid = styled.div`
  display: grid;
  grid-template-columns: 240px;
  padding: 15px 0 !important;

  button {
    text-transform: none;
  }
`;

export const DocTitle = styled(T.SectionTitle)`
  margin: 15px 0;
`;
