import React, { useEffect, useState } from "react";
import { Tabs, Tab, IconButton } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SimpleMapScreenshoter } from "leaflet-simple-map-screenshoter";
import { getPlg } from "http-services/data-access";
import { Plg } from "types/data-access";
import Modal from "./ModalDataAccess";
import FilterPanel from "./FilterPanel";
import SearchPanel from "./SearchPanel";
import * as S from "./styles";
import useConfig from "services/config/useConfig";

export type SearchBarProps = {
  upn?: string;
  onActivePolygonChange?: (upn: string) => Promise<void>;
  onSearch?: (upn: string) => Promise<void>;
  onFilterChange?: (filterBy: string) => void;
  screenshooter?: SimpleMapScreenshoter;
};

const SearchBar = ({
  upn,
  onSearch,
  screenshooter,
  onFilterChange: onFilterChangeCallback,
}: SearchBarProps) => {
  const { language, translation } = useConfig();

  const tabs = [
    { index: 0, label: translation.map.search.tabs.search[language] },
    { index: 1, label: translation.map.search.tabs.filter[language] },
  ];
  const [modalIsOpen, setModalIsOpen] = useState(false);

  // Control
  const [tab, setTab] = useState(-1);
  const [collapsedTab, setCollapsedTab] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  // Data
  const [plg, setPlg] = useState<Plg | null>(null);

  useEffect(() => {
    if (!upn) return;

    (async () => {
      setIsLoading(true);

      const plg = await getPlg(upn);

      setPlg(plg);
      setIsLoading(false);
      setCollapsedTab(true);
      setTab(0);
    })();
  }, [upn]);

  return (
    <S.SearchWrapper>
      <S.TabWrapper>
        <Tabs
          sx={{ flexGrow: 1 }}
          value={tab}
          onChange={(_, newValue) => {
            setTab(newValue);
            setCollapsedTab(true);
          }}
        >
          {tabs.map(({ index, label }) => (
            <Tab key={index} sx={{ flexGrow: 1 }} label={label} value={index}></Tab>
          ))}
        </Tabs>
        <IconButton
          sx={{ ml: 4, mr: 2 }}
          size="medium"
          onClick={() => setCollapsedTab(!collapsedTab)}
        >
          {collapsedTab && <ExpandLess />}
          {!collapsedTab && <ExpandMore />}
        </IconButton>
      </S.TabWrapper>
      {collapsedTab && (
        <>
          <SearchPanel
            index={0}
            currentTab={tab}
            isLoading={isLoading}
            upn={plg ? upn : ""}
            onSearch={onSearch}
            onClean={() => {
              setPlg(null);
            }}
            plg={plg}
            setModalIsOpen={setModalIsOpen}
          />
          <FilterPanel
            index={1}
            currentTab={tab}
            setIsLoading={setIsLoading}
            onSearch={onSearch}
            onFilterChange={onFilterChangeCallback}
            setFilter={setFilter}
            filter={filter}
            setModalIsOpen={setModalIsOpen}
          />
        </>
      )}

      {plg && (
        <Modal
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          plg={plg}
          screenshooter={screenshooter}
        />
      )}
    </S.SearchWrapper>
  );
};

export default SearchBar;
