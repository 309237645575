import styled from "styled-components";
import { Box } from "@mui/material";

export const ProcessData = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 100%;
  margin-top: 10px;
`;

export const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  margin: 10px;
`;
