import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth";
import config from "./config";

const store = configureStore({
  reducer: {
    auth,
    config,
  },
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
