import React from "react";
import { Box, Pagination as PaginationMUI } from "@mui/material";

type Props = {
  setPage: (n: number) => void;
  count: number;
  page?: number;
};

const Pagination = (props: Props) => {
  const { setPage, ...muiProps } = props;
  const handleChange = (e: any, p: number) => {
    props.setPage(p - 1);
  };

  return (
    <Box
      justifyContent={"center"}
      alignItems="center"
      display={"flex"}
      marginTop="auto"
      padding="30px  0 20px 0"
    >
      <PaginationMUI {...muiProps} color="primary" onChange={handleChange} />
    </Box>
  );
};

export default Pagination;
