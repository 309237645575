import React from "react";
import { Profile } from "types/auth";
import Button from "@mui/material/Button";
import { requestAnalysis as requestAnalysisService } from "http-services/data-access";
import { Status } from "types/data-access";
import Tooltip from "@mui/material/Tooltip";
import useConfig from "services/config/useConfig";

type Props = {
  profile: Profile | undefined;
  status: Status;
  isValidating: boolean;
  setIsValidating: (v: boolean) => void;
  previousStatus: string | boolean;
  upn: string;
  criteriaPage: number;
  setAnalysisRequested: (page: number, s: boolean) => void;
  analysisRequested: boolean;
  handleCancel: () => void;
};

const RequestButton = (props: Props) => {
  const { translation, language } = useConfig();

  const requestAnalysis = async () => {
    if (!props.analysisRequested) {
      await requestAnalysisService(props.upn, props.criteriaPage + 1);
      props.setAnalysisRequested(props.criteriaPage, true);
    }
  };

  const handleClick = () => {
    if (props.isValidating) {
      props.handleCancel();
    }
    props.setIsValidating(!props.isValidating);
  };

  if (props.profile === Profile.TECHNICIAN) {
    return (
      <Tooltip title="Somente processos aptos no pacote anterior podem ser analisados">
        <span>
          <Button
            variant="contained"
            onClick={handleClick}
            color={!props.isValidating ? "primary" : "error"}
            disabled={props.status === Status.Blocked}
          >
            {!props.isValidating
              ? translation.buttons.analysisCriteria.validate[language]
              : translation.buttons.analysisCriteria.cancel[language]}
          </Button>
        </span>
      </Tooltip>
    );
  }

  const alreadyRequested = props.analysisRequested;
  const shouldBlockRequest = props.previousStatus !== Status.Ok;
  const buttonText =
    !shouldBlockRequest && alreadyRequested
      ? "Análise solicitada"
      : "Solicitar análise";
  const tooltipText =
    !shouldBlockRequest && alreadyRequested
      ? "Análise já solicitada, aguarde a verificação por um técnico especialista"
      : "Somente processos aptos no pacote anterior podem ter análise solicitada";

  return (
    <Tooltip title={tooltipText}>
      <Button
        variant="contained"
        onClick={requestAnalysis}
        disabled={shouldBlockRequest || alreadyRequested}
      >
        {buttonText}
      </Button>
    </Tooltip>
  );
};

export default RequestButton;
