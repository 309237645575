import { Box, Container } from "@mui/material"
import Header from "../../components/header"
import RegisterPage from "./Register"
import UserListPage from "./UserList"
import { NAVBAR_HEIGHT } from "../../components/header/components/BaseHeader"

export const Admin = () => {
  return <Container><Box className="">
    <Header />
    <Box style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", padding: "1rem", marginTop: `calc(${NAVBAR_HEIGHT} + 1rem)`, gap: "2rem" }}>
      <RegisterPage />
      <UserListPage />
    </Box>
  </Box>
  </Container>
}
