import { Box } from "@mui/system";
import * as S from "./styles";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

type Props = {
  updatedAt?: string;
  updatedBy?: string;
  requestedAt?: string;
  requestedBy?: string;
};

const formatDate = (string: string) => {
  return moment(string).fromNow();
};

const FooterInfo = (props: Props) => {
  const updateInfo = (): string => {
    if (!props.updatedAt || !props.updatedBy) return "";

    const formated = formatDate(props.updatedAt);

    return `Última edição realizada ${formated} por ${props.updatedBy}`;
  };

  const requestInfo = (): string => {
    if (!props.requestedAt || !props.requestedBy) return "";

    const formated = formatDate(props.requestedAt);

    return `Solicitação de análise realizada ${formated} por ${props.requestedBy}`;
  };

  return (
    <Box mt="30px">
      <S.Paragraph>{updateInfo()}</S.Paragraph>
      <S.Paragraph>{requestInfo()}</S.Paragraph>
    </Box>
  );
};

export default FooterInfo;
