import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { updatePassword } from "http-services/auth";
import Header from "components/header";
import Textfield from "components/inputs/Textfield";
import Button from "components/button/DefaultButton";
import * as T from "components/layout/titles";
import * as S from "./components/styles";
import * as GS from "./styles";

const RecoverPasswordPage = () => {
  const [searchParams, _setSearchParams] = useSearchParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      password: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (data: { password: string }) => {
    try {
      const token = String(searchParams.get("token"));
      await updatePassword({
        password: data.password,
        token,
      });
      navigate("/login");
    } catch (error: any) {
      const { message } = error.response.data;
    }
  };

  return (
    <GS.Page>
      <Header />
      <S.Wrapper>
        <T.Title textAlign="center">
          <span>Alterando a senha</span>
        </T.Title>
        <S.RecoverSubtitle>
          <span>Por favor, redefina sua senha abaixo.</span>
        </S.RecoverSubtitle>

        <Controller
          name="password"
          control={control}
          rules={{
            required: "Campo obrigatório",
          }}
          render={({ field: { onChange, value } }) => (
            <Textfield
              placeholder="Digite uma nova senha"
              value={value}
              onChange={onChange}
              error={Boolean(errors.password?.message)}
              messageError={errors.password?.message}
              type="password"
            />
          )}
        />

        <S.ButtonWrapper marginTop="200px">
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Alterar
          </Button>
        </S.ButtonWrapper>
      </S.Wrapper>
    </GS.Page>
  );
};

export default RecoverPasswordPage;
