export enum Languages {
  PORTUGUESE = "pt-br",
  ENGLISH = "en-us",
}


export const LangNames = {
  "pt-br": {
    [Languages.PORTUGUESE]: "Português",
    [Languages.ENGLISH]: "Portuguese"
  },
  "en-us": {
    [Languages.PORTUGUESE]: "Inglês",
    [Languages.ENGLISH]: "English"
  }
}
