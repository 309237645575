import React, { MutableRefObject } from "react";
import WWFlogo from "assets/image/logo/wwf.jpg";
import NAPlogo from "assets/image/pages/landing-page/NAPlogo.png";
import IgarapeLogo from "assets/image/logo/igarape.png";

import useConfig from "services/config/useConfig";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

type Props = {
  footerRef: MutableRefObject<any>;
};

const Footer = ({ footerRef }: Props) => {
  const { language: l, translation } = useConfig();

  const t = translation.home.footer;

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <footer
      ref={footerRef}
      style={{
        width: "100%",
        background: "#414142",
        color: "white",
        borderBottom: "8px solid #fccb5b",
        paddingTop: "4rem",
        paddingBottom: "4rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: matches ? "row" : "column",
          width: "100%",
          maxWidth: "1300px",
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          justifyContent: matches ? "around" : "center",
          p: 2,
        }}
      >
        <Box sx={{ maxWidth: matches ? "50%" : "100%" }}>
          <Typography sx={{ textTransform: "uppercase", fontSize: "1.5rem", fontWeight: "bold" }}>
            {t.title[l]}
          </Typography>
          <Typography sx={{ marginTop: "1rem", opacity: 0.7 }}>{t.description[l]}</Typography>
        </Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            alignItems: "stretch",
            marginLeft: matches ? "auto" : 0,
            justifyContent: "",
            gap: 2,
            py: 4,
            px: 4,
            backgroundColor: "rgba(255,255,255,1)",
          }}
        >
          <div>
            <Typography sx={{ color: "black" }}>Realização:</Typography>
            <img src={WWFlogo} alt="WWF" style={{ width: "100px", objectFit: "contain" }} />
          </div>
          <Box>
            <Typography sx={{ color: "black", mb: "1rem" }}>Apoio:</Typography>
            <img
              src={NAPlogo}
              alt="NAP"
              style={{
                width: "100px",
                flexShrink: 0,
                height: "auto",
                objectFit: "contain",
              }}
            />
          </Box>
          <img
            src={IgarapeLogo}
            alt="Instituto Igarapé"
            style={{
              width: "150px",
              flexShrink: 0,
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
