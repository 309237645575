import React, { useEffect, useState } from "react";
import Header from "components/header";
import * as S from "./styles";
import Register from "./components/Register";
import Login from "./components/Login";
import RecoverPassword from "./ResetPassword";
import { useNavigate } from "react-router-dom";

enum Mode {
  REGISTER = 1,
  LOGIN = 2,
  RECOVER_PASSWORD = 3,
}

type Props = {
  loginPage?: boolean;
};

const AuthPage = (props: Props) => {
  const navigate = useNavigate();

  const [mode, setMode] = useState<Mode>(
    props.loginPage ? Mode.LOGIN : Mode.REGISTER
  );

  useEffect(() => {
    setMode(props.loginPage ? Mode.LOGIN : Mode.REGISTER);
  }, [props.loginPage]);

  return (
    <S.Page>
      <Header />
      {mode === Mode.LOGIN && (
        <Login
          goToRegister={() => navigate("/register")}
          goToRecover={() => setMode(Mode.RECOVER_PASSWORD)}
        />
      )}
      {mode === Mode.REGISTER && <Register />}
      {mode === Mode.RECOVER_PASSWORD && <RecoverPassword />}
    </S.Page>
  );
};

export default AuthPage;
