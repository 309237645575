import React from "react";
import { Languages } from "types/config/i18n";
import { CriteriaAnalysisResponse, Plg } from "types/data-access";
import PackageLayout from "./Package";
import PackageThree from "./PackageThree";

type Props = {
  mapImage: string;
  package: number;
  displayerImage: string;
  currentCriteriaAnalysisData: CriteriaAnalysisResponse;
  plg: Plg | undefined;
  displayerTitle: string;
  displayerDescription: string;
  displayerImageText: string;
  language: Languages;
};

const Layout = (props: Props) => {
  const status = Boolean(props.plg?.p_isActive) ? "Ativo" : "Inativo";

  if (props.package === 2) {
    return (
      <PackageThree
        mapImage={props.mapImage}
        upn={String(props.plg?.p_upn)}
        currentCriteriaAnalysisData={props.currentCriteriaAnalysisData}
        plgName={String(props.plg?.titular_name)}
        phase={String(props.plg?.p_phase)}
        status={status}
        displayerImage={props.displayerImage}
        displayerTitle={props.displayerTitle}
        displayerDescription={props.displayerDescription}
        displayerImageText={props.displayerImageText}
        potential={props.currentCriteriaAnalysisData.compliancePotential || 0}
        language={props.language}
      />
    );
  }

  return (
    <PackageLayout
      mapImage={props.mapImage}
      upn={String(props.plg?.p_upn)}
      currentCriteriaAnalysisData={props.currentCriteriaAnalysisData}
      plgName={String(props.plg?.titular_name)}
      phase={String(props.plg?.p_phase)}
      status={status}
      displayerImage={props.displayerImage}
      displayerTitle={props.displayerTitle}
      displayerDescription={props.displayerDescription}
      displayerImageText={props.displayerImageText}
      language={props.language}
    />
  );
};

export default Layout;
