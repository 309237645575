import styled from "styled-components";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import * as T from "components/layout/titles";

export const Title = styled(T.Title)`
  padding: 30px 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 30%;
  padding-top: 10%;
  row-gap: 10px;

  & > span {
    text-align: center;
  }
`;

type ButtonWrapperProps = {
  marginTop?: string;
};

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  display: grid;
  padding: 0 6px;
  margin-top: ${(props) => props.marginTop || "30px"};
  row-gap: 20px;
`;

type SubtitleProps = {
  noMargin?: boolean;
};

export const RecoverSubtitle = styled.div<SubtitleProps>`
  margin-bottom: ${(props) => (props.noMargin ? "0" : "25px")};
  font-size: 20px;
  text-align: center;
  color: #333333;
`;

export const EmailIcon = styled(ForwardToInboxIcon)`
  color: ${(props) => props.theme.colors.blue};
  margin: 30px 0;
`;
