import { ThemeProvider } from "@mui/material/styles";
import { ThemeProvider as SCThemeProvider } from "styled-components";

import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import LandingPage from './pages/landing'
import Map from "pages/map/Map";
import Auth from "pages/auth"
import RecoverPassword from 'pages/auth/RecoverPassword';
import RouteGuard from 'components/auth/RouteGuard';
import { useDispatch, useSelector } from "react-redux";
import { StorageAuthKeys } from 'services/auth/useAuth';
import { authenticate, Getters, setUserdata, setIsLoaded, logout } from "store/auth";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import {
  checkAuthToken,
} from "http-services/auth";
import { createTheme } from '@mui/material';
import themeOverrides from 'theme';
import { Admin } from "pages/admin";
import { IntlProvider } from "react-intl";

const qClient = new QueryClient();

const theme = createTheme({
  ...themeOverrides,
  typography: {
    fontFamily: [
      'Inter',
      'sans-serif',
    ].join(','),
  },
});

function App() {
  const isAuthDataLoaded = useSelector(Getters.isLoaded);
  const dispatch = useDispatch()

  const accessToken = localStorage.getItem(StorageAuthKeys.Token);
  const userName = localStorage.getItem(StorageAuthKeys.UserName);
  const userProfile = localStorage.getItem(StorageAuthKeys.UserProfile);

  const checkAuthentication = async () => {
    if (!accessToken || !userName || !userProfile) {
      return false;
    }

    const isAuthenticated = await checkAuthToken();

    if (!isAuthenticated) {
      return false;
    }

    return true;
  };

  const handleAuth = async () => {
    const isAuthenticated = await checkAuthentication();

    if (isAuthenticated) {
      dispatch(setUserdata({ name: userName, profile: userProfile }));
      dispatch(authenticate());
    } else {
      dispatch(logout());
    }

    dispatch(setIsLoaded(true));
  }

  useEffect(() => {
    handleAuth()
  }, [])

  return (
    <IntlProvider locale="pt" defaultLocale="pt">
    <React.Fragment>
      <QueryClientProvider client={qClient}>
        <div className="App">
          {
            isAuthDataLoaded && <>
              <SCThemeProvider theme={themeOverrides}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />

                  <BrowserRouter>
                    <Routes>
                      <Route path="/">
                        <Route index element={<RouteGuard><LandingPage /></RouteGuard>} />
                        <Route path="map" element={<RouteGuard><Map /></RouteGuard>} />
                        <Route path="admin/*" element={<RouteGuard><Admin /></RouteGuard>} />
                        <Route path="login" element={<Auth loginPage={true} />} />
                        <Route path="register" element={<Auth loginPage={false} />} />
                        <Route path="recover-password" element={<RecoverPassword />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </ThemeProvider>
              </SCThemeProvider>
            </>
          }


        </div>
      </QueryClientProvider>
    </React.Fragment>
    </IntlProvider>
  );
}

export default App;
