import { StyleSheet } from "@react-pdf/renderer";

export const pageStyle = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  space: {
    width: "100%",
    height: "0.5cm",
  },
});

export const criteriaStyle = StyleSheet.create({
  root: {
    width: "100%",
    paddingHorizontal: "1cm",
    marginBottom: 0,
    paddingBottom: 0,
  },
  title: {
    fontSize: "15px",
    height: "1cm",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  itemsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemWrapper: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
  },
  item: {
    display: "flex",
    flexDirection: "row",
    height: "20px",
  },
  itemButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#79AF65",
    fontSize: "9px",
    color: "white",
    borderRadius: "5px",
    width: "3cm",
    height: "20px",
  },
  itemText: {
    display: "flex",
    paddingLeft: "10px",
    fontSize: "9px",
    justifyContent: "center",
    alignItems: "center",
    height: "20px",
    color: "#4B5768",
    flexWrap: "wrap",
    marginBottom: "10px",
    maxWidth: "5cm",
  },
  itemNote: {
    display: "flex",
    fontSize: "10px",
    maxHeight: "50px",
    color: "#4B5768",
    flexWrap: "wrap",
    marginBottom: "10px",
    padding: "5px",
  },
});

export const displayerStyle = StyleSheet.create({
  wrapper: {
    display: "flex",
    width: "100%",
    padding: "1cm",
    maxHeight: "4cm",
    flexDirection: "row",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "0.2cm",
  },
  image: {
    width: "1.5cm",
    height: "1.5cm",
  },
  imageText: {
    fontSize: "10px",
  },
  textWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginLeft: "1cm",
    height: "4cm",
  },
  title: {
    display: "flex",
    height: "20px",
    fontSize: "12px",
  },
  description: {
    display: "flex",
    flex: 1,
    fontSize: "10px",
  },
});

export const mapStyle = StyleSheet.create({
  wrapper: {
    display: "flex",
    width: "100%",
    paddingHorizontal: "1cm",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "10cm",
    height: "5cm",
  },
});
