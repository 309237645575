import React, { useCallback } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Plg } from "types/data-access";
import { getRawUpn } from "utils/upn";
import Button from "components/button/DefaultButton";
import { TabPanel } from "../styles";
import * as S from "./styles";
import SearchInput from "./SearchInput";
import { useProperties } from "./properties";
import useConfig from "services/config/useConfig";

type Props = {
  index: number;
  currentTab: number;
  isLoading: boolean;
  upn?: string;
  plg: Plg | null;
  onSearch?: (upn: string) => Promise<void>;
  onClean?: () => void;
  setModalIsOpen: (v: boolean) => void;
};

const SearchPanel = (props: Props) => {
  const { onSearch } = props;

  const properties = useProperties();
  const { translation, language } = useConfig();

  // Update upn and search for boundaries if form changes
  const onQueryChange = useCallback(
    ({ query }: { query: string }) => {
      onSearch?.(getRawUpn(query));
    },
    [onSearch],
  );

  return (
    <TabPanel index={props.index} currentTab={props.currentTab}>
      <SearchInput
        disabled={props.isLoading}
        value={props.upn}
        onChange={onQueryChange}
      ></SearchInput>
      {props.isLoading ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "30px 0",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        props.plg && (
          <S.ProcessData>
            <Typography variant="subtitle1" sx={{ fontWeight: "700" }}>
              {translation.map.search.caseData.title[language]}
            </Typography>

            {properties.map(({ label, key, format }) => {
              const v = props.plg?.[key as keyof Plg];

              let value;

              value = format ? format(value ?? v) : value ?? v;

              return (
                <Box key={label} sx={{ marginBottom: 1 }}>
                  <Typography sx={{ color: "#da9121", fontWeight: "medium" }}>{label}</Typography>
                  <Typography sx={{ color: "#4B5768", fontWeight: "medium" }}>
                    {value ?? "Não há dados para serem exibidos"}
                  </Typography>
                </Box>
              );
            })}
          </S.ProcessData>
        )
      )}
      {props.plg && (
        <S.ButtonWrapper sx={{ gap: 2 }}>
          <Button variant="contained" onClick={() => props.setModalIsOpen(true)}>
            {translation.buttons.map.seeAnalysis[language]}
          </Button>
          <Button variant="contained" onClick={() => props?.onClean?.()} sx={{
            ":disabled": {
              color: "black",
              background: "transparent",
              opacity: 0.2,
            }
          }}>
            {translation.buttons.map.reset[language]}
          </Button>
        </S.ButtonWrapper>
      )}
    </TabPanel>
  );
};

export default SearchPanel;
