import { Badge, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useConfig from "services/config/useConfig";
import * as S from './styles';
import { useQuery } from "@tanstack/react-query";
import { client } from "../../http-services/client";
import { DeleteOutline } from "@mui/icons-material";
import { set } from "lodash";
import { useAuth, useAuthData } from "../../services/auth";

const fetchUsers = async () => {
  const response = await client.get(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/users`);

  return response.data.sort((a: any, b: any) => b.id - a.id);
}

const fetchCurrentUser =  async () => {
   const response = await client.get(`${process.env.REACT_APP_BACKEND_URL}/auth/me`);
  return response.data;
}


const UserListPage = () => {
  const navigate = useNavigate();
  const { language, translation } = useConfig();
  const [open, setOpen] = useState({ delete: false, activate: false });
  const [userId, setUserId] = useState<string>("");
  const [userActive, setUserActive] = useState(false);

  const {
    data: users,
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(["admin", "users"], fetchUsers);

  const {
    data: currentUser,
  } = useQuery(["auth", "currentUser"], fetchCurrentUser);

  const onDeleteUser = async (id: string) => {
    setOpen({ ...open, delete: true });
    setUserId(id);
  };

  const onActivateUser = async (e: any, id: string) => {
    e.preventDefault();

    const active = e.target.checked;
    setOpen({ ...open, activate: true });
    setUserId(id);
    setUserActive(active);
  };

  const deleteUser = async () => {
    await client.delete(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/users/${userId}`);
    setOpen({ ...open, delete: false });
    setUserId("");
    refetch();
  }

  const activateUser = async () => {
    await client.post(`${process.env.REACT_APP_BACKEND_URL}/auth/admin/users/${userId}/activate`, { active: userActive });
    setOpen({ ...open, activate: false });
    setUserId("");
    refetch();
  }

  const handleClose = (event?: any) => {
    setOpen({ delete: false, activate: false });
  }

  const isActive = users?.find((u: any) => u.id === userId)?.isActive;

  return <Box style={{ display: "flex", flexDirection: "column"}}>
    <S.Title textAlign="left">
      {translation.auth.list.title.main[language]}
    </S.Title>
    { isLoading && <CircularProgress />}
    {!isLoading && users && users.map((user: any) => {
      if (user.id === currentUser?.userId) return <></>;

      return <Box key={user.id} style={{ padding: "0.5rem 1rem", borderBottom: "1px solid #ddd", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>{user.name} ({user.email})</Box>
        <Box style={{ display: "flex", alignItems: "center", gap: "0.25rem",}} >
          <IconButton color="error" onClick={() => onDeleteUser(user.id)}><DeleteOutline /></IconButton>
          <Switch checked={user.isActive} onChange={(e) => onActivateUser(e, user.id)} />
        </Box>
      </Box>
    })}

    <Dialog
      open={open.delete}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Deletar usuário?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ao deletar o usuário, ele não poderá ser recuperado. Ele não terá mais acesso à plataforma. Você poderá criar um novo acesso novamente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={deleteUser} autoFocus>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>

    <Dialog
      open={open.activate}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`${isActive ? "Desativar" : "Ativar"} usuário?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {isActive ? "Ao desativar o usuário, ele não terá mais acesso a plataforma. Os dados do cadastro serão preservados." : "Ao ativar o usuário, ele passará a ter acesso a plataforma e todas as suas funcionalidades." }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={activateUser} autoFocus>
          {isActive ? "Desativar" : "Ativar"}
        </Button>
      </DialogActions>
    </Dialog>
  </Box>
};

export default UserListPage;
