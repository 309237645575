import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Textfield from "components/inputs/Textfield";
import Select from "components/inputs/Select";
import Button from "components/button/DefaultButton";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { Profile } from "types/auth";
import { useAuth } from "services/auth";
import { RegisterDto } from "http-services/auth";
import useConfig from "services/config/useConfig";
import { Snackbar } from "@mui/material";

const RegisterPage = () => {
  const navigate = useNavigate();
  const { register } = useAuth();
  const { language, translation } = useConfig();
  const [open,setOpen] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      profile: null,
      password: "",
    },
  });

  const onSubmit = async (data: Omit<RegisterDto, "profile">) => {
    await register(data as RegisterDto, (message) => {
      if (message === "The request conflicts with an existing entity.")
        setError("email", {
          message:
            translation.errorMessage.form.emailAlreadyRegistered[language],
          type: "custom",
        });
    }).then(() => {
      setOpen(true);
      reset();
      setTimeout(() => {
        navigate("/");
      }, 5000)
    });
  };

  return (
    <S.Wrapper>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        message="Usuário criado com sucesso! Aguarde um administrador aprovar o seu cadastro para entrar."
      />
      <S.Title textAlign="center">
        {translation.auth.register.title.main[language]}
      </S.Title>
      <Controller
        name="name"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.name[language]}
            placeholder={
              translation.auth.register.form.placeholder.name[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.name?.message)}
            messageError={errors.name?.message}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
          pattern: {
            value:
              /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi,
            message: translation.errorMessage.form.invalidEmail[language],
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.email[language]}
            placeholder={
              translation.auth.register.form.placeholder.email[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.email?.message)}
            messageError={errors.email?.message}
          />
        )}
      />
      <Controller
        name="profile"
        control={control}
        rules={{
          validate: (value) =>
            value !== null || translation.errorMessage.form.required[language],
        }}
        render={({ field: { onChange, value } }) => (
          <Select
            value={value}
            sx={{ margin: "0", p: 1, fontSize: "1rem" }}
            onChange={onChange}
            error={Boolean(errors.profile?.message)}
            messageError={errors.profile?.message}
            items={[
              {
                value: null,
                label: "Selecione aqui o perfil do usúario",
                disabled: true,
              },
              {
                value: Profile.TECHNICIAN,
                label:
                  translation.auth.register.form.values.profile.technician[
                    language
                  ],
              },
              {
                value: Profile.PRODUCER,
                label:
                  translation.auth.register.form.values.profile.producer[
                    language
                  ],
              },
            ]}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        rules={{
          required: translation.errorMessage.form.required[language],
          minLength: {
            value: 8,
            message: "A senha deve conter no mínimo 8 caracteres",
          },
        }}
        render={({ field: { onChange, value } }) => (
          <Textfield
            label={translation.auth.register.form.title.password[language]}
            placeholder={
              translation.auth.register.form.placeholder.password[language]
            }
            value={value}
            onChange={onChange}
            error={Boolean(errors.password?.message)}
            messageError={errors.password?.message}
            type="password"
          />
        )}
      />
      <S.ButtonWrapper>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Entrar
        </Button>
        <Button variant="text" onClick={() => navigate("/")}>
          Voltar
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default RegisterPage;
