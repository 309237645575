import { Languages } from "types/config/i18n";

export const pdf = {
  header: {
    case: {
      [Languages.PORTUGUESE]: "Processo",
      [Languages.ENGLISH]: "Case",
    },
    holder: {
      [Languages.PORTUGUESE]: "Titular",
      [Languages.ENGLISH]: "Holder",
    },
    phase: {
      [Languages.PORTUGUESE]: "Fase",
      [Languages.ENGLISH]: "Phase",
    },
    situation: {
      [Languages.PORTUGUESE]: "Situação",
      [Languages.ENGLISH]: "Situation",
    },
  },
  footer: {
    issue: {
      [Languages.PORTUGUESE]: "Data e hora da emissão",
      [Languages.ENGLISH]: "Issue date and time",
    },
  },
};
