import useConfig from "services/config/useConfig";

type ListProps = {
  label: string;
  key: string;
  format?: <T>(value: T) => string;
};

export const useProperties = (): ListProps[] => {
  const { translation, language } = useConfig();

  return [
    {
      label: translation.map.search.caseData.nup[language],
      key: "p_upn",
    },
    {
      label: translation.map.search.caseData.applicant[language],
      key: "titular_name",
    },
    {
      label: translation.map.search.caseData.situation[language],
      key: "p_isActive",
      format: (value) => {
        return value ? "Ativo" : "Inativo";
      },
    },
    {
      label: translation.map.search.caseData.substance[language],
      key: "p_substance",
    },
    {
      label: translation.map.search.caseData.phase[language],
      key: "p_phase",
    },
    {
      label: translation.map.search.caseData.lastEvent[language],
      key: "events_event",
    },
    {
      label: translation.map.search.caseData.county[language],
      key: "p_city",
    },
  ];
};
