import React, { PropsWithChildren } from "react";
import { Page, View, Text } from "@react-pdf/renderer";
import { pageStyle, headerStyle, footerStyle } from "./styles";
import useConfig from "services/config/useConfig";
import { Languages } from "types/config/i18n";
import * as translation from "services/config/i18n";

type Props = {
  upn: string;
  plgName: string;
  phase: string;
  status?: string;
  language: Languages;
};

const Layout = (props: Props & PropsWithChildren) => {
  const { upn, plgName, phase, status, children, language } = props;

  const date = new Date();

  const parseDateNumber = (date: number | string) =>
    Number(date) >= 10 ? date : String(`0${date}`);

  const footerText = `${
    translation.pdf.footer.issue[language]
  } - ${date.getHours()}:${date.getMinutes()} ${parseDateNumber(
    date.getDate()
  )}/${parseDateNumber(date.getMonth() + 1)}/${date.getFullYear()}`;

  return (
    <Page size="A4" style={pageStyle.page}>
      <View style={headerStyle.root}>
        <View style={headerStyle.row}>
          <View style={headerStyle.rowItem}>
            <Text>{`${translation.pdf.header.case[language]}: ${upn}`}</Text>
            <Text>{`${translation.pdf.header.case[language]}:`}</Text>
          </View>
          <View style={headerStyle.rowItem}>
            <Text>{`${translation.pdf.header.phase[language]}: ${phase}`}</Text>
            <Text>{`${translation.pdf.header.phase[language]}:`}</Text>
          </View>
        </View>

        <View style={headerStyle.rowLower}>
          <View style={headerStyle.rowItem}>
            <Text>{`${translation.pdf.header.holder[language]}: ${plgName}`}</Text>
            <Text>{`${translation.pdf.header.holder[language]}:`}</Text>
          </View>
          <View style={headerStyle.rowItem}>
            <Text>{`${translation.pdf.header.situation[language]}: ${status}`}</Text>
            <Text>{`${translation.pdf.header.situation[language]}:`}</Text>
          </View>
        </View>
      </View>

      {children}

      <View style={footerStyle.root}>
        <Text style={footerStyle.text}>{footerText} </Text>
      </View>
    </Page>
  );
};

export default Layout;
