import React from "react";
import { View, Document, Image, Text } from "@react-pdf/renderer";
import { CriteriaAnalysisResponse } from "types/data-access";
import { statusText } from "../../../CriteriaResult/info";
import { Status } from "types/data-access";
import { criteriaStyle, displayerStyle, mapStyle } from "../styles";
import DefaultPage from "../Default";
import { Languages } from "types/config/i18n";
import * as translation from "services/config/i18n";

type Props = {
  mapImage: string;
  upn: string;
  currentCriteriaAnalysisData: CriteriaAnalysisResponse;
  plgName: string;
  phase: string;
  status: string;
  displayerImage: string;
  displayerTitle: string;
  displayerDescription: string;
  displayerImageText: string;
  language: Languages;
};

const Layout = (props: Props) => {
  const {
    mapImage,
    upn,
    currentCriteriaAnalysisData,
    plgName,
    phase,
    status,
    displayerDescription,
    displayerImage,
    displayerImageText,
    displayerTitle,
    language,
  } = props;

  const translateComplianceResult = (
    text: string,
    field: "title" | "description"
  ) => {
    const { package1, package2, package3 } = translation.packageCriteria;
    const translationData = [
      package1.complianceResult[field],
      package2.complianceResult[field],
      package3.complianceResult[field],
    ];

    return (
      translationData.find((d) => d[Languages.PORTUGUESE] === text)?.[
        language
      ] || text
    );
  };

  const translateCriteriaStatus = (status: string) => {
    const statusTranslation = Object.values(translation.system.status).find(
      (s) => s?.["pt-br"].toLocaleLowerCase() === status?.toLocaleLowerCase()
    );

    return statusTranslation ? statusTranslation[language] : status;
  };

  const translateCriteria = (key: string) => {
    const { package1, package2, package3 } = translation.packageCriteria;
    const packages = {
      ...package1.criteria,
      ...package2.criteria,
      ...package3.criteria,
    };

    const translationData = Object.entries(packages).find(([k]) => k === key);

    if (translationData) {
      return translationData[1].title[language];
    }

    return "";
  };

  const CriteriaItems = currentCriteriaAnalysisData.criteria.map((criteria) => {
    const buttonColor =
      criteria.status === Status.Ok
        ? "#79AF65"
        : criteria.status === Status.NotOk
        ? "#FF8080"
        : "#A4A4AD";

    return (
      <>
        <View style={criteriaStyle.item}>
          <View
            style={[criteriaStyle.itemButton, { backgroundColor: buttonColor }]}
          >
            <Text>
              {translateCriteriaStatus(statusText[criteria.status as Status])}
            </Text>
          </View>
          <View style={criteriaStyle.itemText}>
            <Text>
              {translateCriteria(criteria.key || "") || criteria.label}
            </Text>
          </View>
        </View>

        <View style={criteriaStyle.itemNote}>
          <Text>
            {(criteria.status as Status) === Status.NotOk ? criteria.note : ""}
          </Text>
        </View>
      </>
    );
  });

  return (
    <Document>
      <DefaultPage
        upn={upn}
        plgName={plgName}
        phase={phase}
        status={status}
        language={language}
      >
        <View style={displayerStyle.wrapper}>
          <View style={displayerStyle.imageWrapper}>
            <Image style={displayerStyle.image} src={displayerImage}></Image>
            <Text style={displayerStyle.imageText}>{displayerImageText}</Text>
          </View>

          <View style={displayerStyle.textWrapper}>
            <View style={displayerStyle.title}>
              <Text>{translateComplianceResult(displayerTitle, "title")}</Text>
            </View>
            <View style={displayerStyle.description}>
              <Text>
                {translateComplianceResult(displayerDescription, "description")}
              </Text>
            </View>
          </View>
        </View>

        <View style={[criteriaStyle.root, { marginTop: "20px" }]}>
          <View style={criteriaStyle.title}>
            <Text>
              {translation.packageCriteria.analysisCriteria[language]}
            </Text>
          </View>

          {CriteriaItems}
        </View>

        <View style={mapStyle.wrapper}>
          <Image
            style={[mapStyle.image, { width: "100%", height: "8cm" }]}
            src={mapImage}
          ></Image>
        </View>
      </DefaultPage>
    </Document>
  );
};

export default Layout;
