import { Languages } from "types/config/i18n";

export const home = {
  carousel: {
    title: {
      [Languages.PORTUGUESE]: "Portal da Transparência do Ouro",
      [Languages.ENGLISH]: "Gold Transparency Portal",
    },
    subtitle: {
      [Languages.PORTUGUESE]: "A plataforma unifica, de forma objetiva, dados públicos relevantes para a análise do nível de conformidade da atividade de mineração de ouro com os parâmetros estabelecidos pela lei no Brasil.\n\nInformações de processos, imagens de satélite, dados financeiros e geográficos são consolidados em um único local.",
      [Languages.ENGLISH]: "The platform provides a clear and objective overview of the relevant data for assessing the level of compliance of gold mining operations with the parameters established by Brazilian law. Data on mining operations, satellite imagery, financial and geographical information are consolidated in a single platform.",
    },
    subtitle2: {
      [Languages.PORTUGUESE]: "O sistema permite avaliar o risco de inadequações nos processos e fornecer informações que podem subsidiar fiscalizações e autuações, servindo como indicativo para a necessidade de investigações mais aprofundadas. Trata-se de uma plataforma eficaz para verificar se o ouro produzido em uma lavra está associado a práticas ilícitas ou irregularidades em seu processo produtivo, apoiando a definição de um caminho a ser seguido para que a extração mineral ocorra de modo responsável.\n\nACESSE!",
      [Languages.ENGLISH]: "The system allows users to assess the risk of non-compliance with the law and provides information that can support inspections and fines, serving as an indication of the need for more robust investigations. It is an effective platform for verifying whether the risk of gold produced in a mining operation being associated with illicit practices or irregularities in its production process, supporting the definition of a path to be followed for responsible mineral extraction.",
    },
    buyer: {
      title: {
        [Languages.PORTUGUESE]: "Plataforma de Compra Responsável de Ouro para o Comprador é",
        [Languages.ENGLISH]: "Buyers can use the Responsible Gold Purchasing Platform (PTO) to",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Uma forma eficaz de verificar o risco de inconformidade do ouro que pretende adquirir. Para isso,o sistema fornece automaticamente um resultado de aptidão dos critérios mínimos do PTO para os processos que pretende acompanhar, além de possibilitar que os compradores solicitem aos técnicos responsáveis análises mais completas dos demais níveis de potencial de regularidade do processo.",
        [Languages.ENGLISH]: `effectively verify the risk of non- compliance prior to acquiring gold.The system automatically analyzes each selected case against the PTO minimum criteria, and buyers can also request in-depth compliance analyses.`,
      },
    },
    technician: {
      title: {
        [Languages.PORTUGUESE]: "Plataforma de Compra Responsável de Ouro para o Técnico é",
        [Languages.ENGLISH]: "Analysts can use the Responsible Gold Purchasing Platform (PTO) to",
      },
      description: {
        [Languages.PORTUGUESE]:
          "Uma definição clara e objetiva de dados relevantes para análise de risco de inconformidade. O sistema facilita ainda mais a análise de risco de inconformidade, centralizando os dados essenciais desta verificação em uma única plataforma, ou seja, os técnicos podem analisar dados de processos, imagens de satélite, informações financeiras e geográficas dos processos, todas em um só lugar.",
        [Languages.ENGLISH]: `efficiently assess compliance risk factors based on case data, satellite imagery and financial and geographical information.`,
      },
    },
    producer: {
      title: {
        [Languages.PORTUGUESE]: "Plataforma de Compra Responsável de Ouro para o Produtor é",
        [Languages.ENGLISH]: "Miners can use the Responsible Gold Purchasing Platform (PTO) to",
      },
      description: {
        [Languages.PORTUGUESE]:
          "A definição clara de um caminho a ser seguido para uma extração mineral responsável. O sistema colabora com o produtor, evidenciando extrações responsáveis ou permitindo uma análise e direcionamento técnico para aumentar seu potencial de regularidade com o PTO.",
        [Languages.ENGLISH]:
          "develop an informed understanding of the criteria for responsible mineral extraction, and receive bespoke analysis and technical guidance to increase the likelihood of compliance with the guidelines.",
      },
    },
  },
  section: {
    main: {
      title: {
        [Languages.PORTUGUESE]: "Plataforma de Compra Responsável de Ouro (PTO)",
        [Languages.ENGLISH]: "Responsible Gold Purchasing Platform (PTO)",
      },
    },
    map: {
      title: {
        [Languages.PORTUGUESE]: "Mapa de Satélite",
        [Languages.ENGLISH]: "Satellite Map",
      },
      subtitle1: {
        [Languages.PORTUGUESE]: "A plataforma de compra responsável de ouro",
        [Languages.ENGLISH]: "Responsible gold purchasing platform",
      },
      subtitle2: {
        [Languages.PORTUGUESE]:
          "Ferramenta de fácil acesso e utilização, de baixo custo, para dar poder de decisão de compra à indústria e aos investidores de ouro.",
        [Languages.ENGLISH]:
          "Low-cost, user-friendly tool to promote informed decision-making by gold industry actors and investors.",
      },
      icons: {
        op: {
          [Languages.PORTUGUESE]: "Operações Legais",
          [Languages.ENGLISH]: "Legal Operations",
        },
        responsible: {
          [Languages.PORTUGUESE]: "Responsáveis",
          [Languages.ENGLISH]: "Responsible Parties",
        },
        standards: {
          [Languages.PORTUGUESE]: "Padrões ESG",
          [Languages.ENGLISH]: "ESG Standards",
        },
      },
    },
    how: {
      title: {
        [Languages.PORTUGUESE]: "Como funciona?",
        [Languages.ENGLISH]: "Features",
      },
      subtitle: {
        [Languages.PORTUGUESE]:
          "A plataforma permite aos usuários analisarem as operações minerárias de três (03) diferentes formas:",
        [Languages.ENGLISH]:
          "The platform allows users to analyze mining operations in three different ways:",
      },
      accordion: {
        interaction: {
          title: {
            [Languages.PORTUGUESE]: "Interação online e imediata",
            [Languages.ENGLISH]: "Rapid Online Analysis",
          },
          process: {
            1: {
              [Languages.PORTUGUESE]: "Online/Algoritmos de Procura",
              [Languages.ENGLISH]: "Search Algorithms",
            },
            2: {
              [Languages.PORTUGUESE]: "Análise ANM (dados do processo)",
              [Languages.ENGLISH]: "National Mining Agency (ANM) case data",
            },
            3: {
              [Languages.PORTUGUESE]: "Análise Receita Federal (CFEM)",
              [Languages.ENGLISH]: "Federal Revenue Service data (CFEM)",
            },
            4: {
              [Languages.PORTUGUESE]: "Shape (Unidades de Conservação, Terras indígenas)",
              [Languages.ENGLISH]: "Shapefiles for Indigenous Lands and Conservation Units",
            },
          },
          criteria: {
            1: {
              [Languages.PORTUGUESE]: "Em fase adequada para mineração",
              [Languages.ENGLISH]: "Current stage allows for mining",
            },
            2: {
              [Languages.PORTUGUESE]: "Em situação adequada para mineração",
              [Languages.ENGLISH]: "Current status allows for mining",
            },
            3: {
              [Languages.PORTUGUESE]: "Poligonal fora de Terra indígena",
              [Languages.ENGLISH]: "No overlap with Indigenous Lands",
            },
            4: {
              [Languages.PORTUGUESE]: "Poligonal fora de Unidade de conservação",
              [Languages.ENGLISH]: "No overlap with Conservation Units",
            },
            5: {
              [Languages.PORTUGUESE]: "Licenciamento ambiental protocolado",
              [Languages.ENGLISH]: "Registered Environmental License",
            },
            6: {
              [Languages.PORTUGUESE]: "Pagamento tributário obrigatório (CFEM)",
              [Languages.ENGLISH]: "Mandatory tax payment (CFEM)",
            },
          },
        },
        techAnalysis: {
          title: {
            [Languages.PORTUGUESE]: "Análise técnica (Imagem de Satélite)",
            [Languages.ENGLISH]: "Geospatial Analysis (Satellite Imagery)",
          },
          process: {
            1: {
              [Languages.PORTUGUESE]: "Análise de técnico PTO",
              [Languages.ENGLISH]: "Analysis conducted by PTO specialist",
            },
          },
          criteria: {
            1: {
              [Languages.PORTUGUESE]: "Poligonal sem transbordamento de área",
              [Languages.ENGLISH]: "Polygon does not exceed authorized area",
            },
            2: {
              [Languages.PORTUGUESE]: "Poligonal fora de territórios tradicionais",
              [Languages.ENGLISH]: "Polygon does not overlap with protected areas",
            },
            3: {
              [Languages.PORTUGUESE]: "Poligonal fora de zona de amortecimento",
              [Languages.ENGLISH]: "Polygon does not overlap with buffer zones",
            },
            4: {
              [Languages.PORTUGUESE]: "Lavra Fantasma",
              [Languages.ENGLISH]: "Detection of fraudulent licenses",
            },
          },
        },
        product: {
          title: {
            [Languages.PORTUGUESE]: "Interação produto e técnico (documentos)",
            [Languages.ENGLISH]: "In-Depth Analysis (Documentation)",
          },
          process: {
            1: {
              [Languages.PORTUGUESE]: "Análise de técnico PTO",
              [Languages.ENGLISH]: "Analysis conducted by PTO specialist",
            },
          },
          criteria: {
            1: {
              [Languages.PORTUGUESE]: "Análise do Relatório Anual de Lavra (RAL)",
              [Languages.ENGLISH]: "Valid Annual Mining Report (RAL)",
            },
            2: {
              [Languages.PORTUGUESE]: "Análise Licença da operação (LO)",
              [Languages.ENGLISH]: "Valid License to Operate (LO)",
            },
            3: {
              [Languages.PORTUGUESE]:
                "Análise das condicionantes ambientais em relação a Licença de operação",
              [Languages.ENGLISH]: "Environmental constraints on LO",
            },
            4: {
              [Languages.PORTUGUESE]: "Análise de tratamentos dos rejeitos",
              [Languages.ENGLISH]: "Adequate tailings management",
            },
            5: {
              [Languages.PORTUGUESE]: "Análise da proximidade de comunidades locais",
              [Languages.ENGLISH]: "Proximity to local communities",
            },
            6: {
              [Languages.PORTUGUESE]: "Análise do Plano de Recuperação de Área degradada (PRAD)",
              [Languages.ENGLISH]: "Adequate Degraded Area Recovery Plan (PRAD)",
            },
            7: {
              [Languages.PORTUGUESE]: "Sem operação irregular nas proximidades",
              [Languages.ENGLISH]: "Proximity to irregular operations",
            },
          },
        },
      },
    },
  },
  footer: {
    title: {
      [Languages.PORTUGUESE]: "Cooperação",
      [Languages.ENGLISH]: "Cooperation",
    },
    description: {
      [Languages.PORTUGUESE]: "Esta iniciativa foi concebida e liderada pelo WWF-Brasil, com desenvolvimento técnico e curadoria realizados pelo Núcleo de Pesquisa para a Mineração Responsável da Universidade de São Paulo e apoio institucional do Instituto Igarapé.",
      [Languages.ENGLISH]: "This initiative was conceived and led by WWF-Brazil, with technical development and curation carried out by the Research Center for Responsible Mining of the University of São Paulo and institutional support from the Igarapé Institute.",
    },
  },
  about: {
    title: {
      [Languages.PORTUGUESE]: "Sobre a iniciativa",
      [Languages.ENGLISH]: "About the initiative",
    },
    description: {
      [Languages.PORTUGUESE]: "O PTO visa apoiar o Poder Público na melhoria da transparência dos processos de produção, compra e venda de ouro em áreas concedidas para exploração por permissão de lavra garimpeira (PLG) e/ou concessões de lavra.\n\nO sistema cruza bases de dados públicas disponibilizadas pelos órgãos de controle e utiliza técnicas de sensoriamento remoto para realizar uma análise de risco sobre a conformidade das áreas analisadas. Esta análise segue critérios pré-determinados por lei que atestam a regularidade das operações, centralizando todas as informações essenciais em uma única plataforma.",
      [Languages.ENGLISH]: "The PTO is an initiative that aims to support the Government in improving the transparency processes of the production, purchase and sale of gold in areas granted for exploration through garimpeira mining permits (PLG) and / or mining concessions.\n\nThe system crosses public databases, made available free of charge by the control agencies and remote sensing techniques, performing a risk analysis about the conformity of the areas analyzed, to criteria predetermined by Law and that attest to the regularity of the operation, centralizing essential information of this verification in a single platform.",
    },
  },
  FAQ: {
    title: {
      [Languages.PORTUGUESE]: "Como funciona",
      [Languages.ENGLISH]: "How it works",
    },
    description: {
      [Languages.PORTUGUESE]: "O sistema utiliza informações disponíveis em bases de dados públicos de diferentes órgãos do governo federal para permitir uma análise automatizada das Permissões de Lavra Garimpeira. Além disso, permite consultas a imagens de satélite mensais para verificar a efetiva utilização das áreas para extração mineral. Em uma fase mais avançada, informações fornecidas pelos produtores de ouro sobre a regularidade de suas lavras podem reduzir significativamente o risco de ilegalidade na primeira compra.\n\nOs critérios de análise do PTO são divididos em três pacotes, a partir dos quais são gerados relatórios que apontam a conformidade do processo mineral em relação aos atributos observados.",
      [Languages.ENGLISH]: "The system is structured based on information available in public databases from different federal government agencies, allowing an automated analysis of the situation of the Garimpeira Mining Permits in this database. In addition, it is possible to consult monthly satellite images to verify the effective use of the areas for mineral extraction. In its most demanding phase, information provided by gold producers regarding the regularity of their mines can significantly reduce the risk of illegality in the first purchase.\n\nThe PTO analysis criteria have been subdivided into three packages, from which reports are generated that indicate the conformity of the analyzed mineral process in relation to the observed attributes."
    },
    pkg: {
      0: {
        title: {
          [Languages.PORTUGUESE]: "Pacote 1",
          [Languages.ENGLISH]: "Package 1"
        },
        description: {
          [Languages.PORTUGUESE]: "No pacote 1, o processo minerário é analisado a partir de algoritmos de busca, que cruzam dados abertos disponibilizados pela ANM (dados do processo minerário), Receita Federal (CFEM), MMA (Unidades de Conservação), FUNAI (Terras Indígenas) e IBAMA (áreas embargadas). A partir daí é possível verificar se o processo alvo está:\n\n\tA) Em fase adequada para mineração\nB) Em situação adequada para mineração\nC) Tem a poligonal fora de Terra indígena\nD) Possui poligonal fora de Unidade de conservação\nE) Tem licenciamento ambiental protocolado\nF) Está regular com o pagamento tributário obrigatório (CFEM)\nG)Não está embarga ambientalmente pelo IBAMA\nH) Protocolou o Relatório anual de Lavra (RAL)",
          [Languages.ENGLISH]: "In package 1, the mining process is analyzed based on search algorithms, which cross-reference open data provided by ANM (mining process data), Federal Revenue Service (CFEM), MMA (Conservation Units), FUNAI (Indigenous Lands) and IBAMA (embargoed areas). From there, it is possible to verify if the target process is:\n\nA) In a suitable stage for mining\nB) In a suitable situation for mining\nC) The polygon is outside Indigenous Land\nD) The polygon is outside Conservation Unit\nE) Has registered environmental licensing\nF) Is compliant with mandatory tax payment (CFEM)\nG) Is not environmentally embargoed by IBAMA\nH) Has filed the Annual Mining Report (RAL)"
        }
      },
      1: {
        title: {
          [Languages.PORTUGUESE]: "Pacote 2",
          [Languages.ENGLISH]: "Package 2"
        },
        description: {
          [Languages.PORTUGUESE]: "Após aprovação nos critérios do pacote 1, a análise avança, com a interpretação por parte dos técnicos das imagens georreferenciadas do satélite Planet, com atualização mensal, para aferir a ocorrência de:\n\nA) Transbordamento de área explorada das poligonais\nB) Lavra Fantasma",
          [Languages.ENGLISH]: "After approval of the criteria in package 1, the analysis advances, with the interpretation by the technicians of the georeferenced images from the Planet satellite, with monthly updates, to assess the occurrence of:\n\nA) Overflow of the explored area of the polygons\nB) Ghost Mining"
        }
      },
      2: {
        title: {
          [Languages.PORTUGUESE]: "Pacote 3",
          [Languages.ENGLISH]: "Package 3"
        },
        description: {
          [Languages.PORTUGUESE]: "Com a aprovação nos critérios estabelecidos nas fases 1 e 2, inicia-se a última etapa. Para a adequada análise dos critérios na fase 3, faz-se necessário que o produtor submeta dados que não são disponibilizados publicamente pelos órgãos de controle. A partir daí, é possível avaliar:\n\nA) Informações prestadas no  Relatório Anual de Lavra (RAL)\nB) Licença de operação (LO)\nC) Atendimento às condicionantes ambientais impostas pelos órgãos ambientais\nD) Forma de tratamentos dos rejeitos\nE) Proximidade de comunidades locais\nF) Análise do Plano de Recuperação de Área degradada (PRAD)\nG) Se há operação irregular nas proximidades",
          [Languages.ENGLISH]: "With the approval of the criteria established in phases 1 and 2, the last stage begins. For the proper analysis of the criteria in phase 3, it is necessary for the producer to submit data that are not made publicly available by the control agencies. From there, it is possible to evaluate:\n\nA) Information provided in the Annual Mining Report (RAL)\nB) License to operate (LO)\nC) Compliance with environmental conditions imposed by environmental agencies\nD) Form of treatment of tailings\nE) Proximity to local communities\nF) Analysis of the Degraded Area Recovery Plan (PRAD)\nG) If there is irregular operation nearby"
        }
      }
    }
  }
};
